import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue, setCryptoDecimals } from "../utils";
import { toast } from "react-hot-toast";
import { GiBackwardTime } from "react-icons/gi";
import ForgotPasswordModal from "./ForgotPasswordModal";
import TipsHistoryModal from "./TipsHistoryModal";
import { postAPIHandler } from "../ApiConfig/service";
import { AuthContext } from "../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .imgBox": {
      "& img": {
        width: "144px",
      },
    },
    "& .classHistory": {
      "& .MuiIconButton-root": {
        padding: "5px 0px 2px 12px",
        color: "#fff",
      },
      "& .MuiButtonBase-root": {
        alignItems: "start",
      },
      "& p": {
        display: "flex",
        alignItems: "end",
      },
    },
    "& .amountBoxClx": {
      display: "flex",
      width: "100%",
      gap: "8px",
      "& .iconButton": {
        background: "#2F3034",
        borderRadius: "5px",
        height: "52px",
      },
    },
    "& .balanceAvaiclx": {
      background: "#1D1F23",
      padding: "12px 16px",
      borderRadius: "5px",
      fontSize: "11px",
      lineHeight: "24px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "14px",
      },
      "& p": {
        fontSize: "14px",
      },
    },
  },
}));
export default function TipsModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { userData } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [tipsHistoryOpen, setTipsHistoryOpen] = useState(false);
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const initialValues = {
    username: "",
    amount: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    amount: yup
      .string()
      .trim()
      .required("Please enter amount.")
      .max(8, "You can enter only 8 characters."),
    username: yup
      .string()
      .required("Username is required.")
      .matches(/^[a-zA-Z0-9_]{3,20}$/, "Please enter a valid username.")
      .min(3, "Please enter atleast 3 characters.")
      .max(32, "You can enter only 32 characters."),
    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "Tips",
        dataToSend: {
          username: values.username,
          amount: Number(values.amount),
          password: values.password,
        },
      });
      if (response.data.statusCode === 200) {
        toast.success(response.data.responseMessage);
        handleClose();
      } else {
        toast.error(response.data.responseMessage);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <GiBackwardTime color="#fff" />
          &nbsp;&nbsp;
          <Typography variant="h5">TIPS</Typography>
        </Box>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) =>
              auth?.userData?.balance >= values.amount
                ? handleSubmitDataFun(values)
                : toast.error("Low Balance.")
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={2} className="withoutAdclx">
                  <Box className="classHistory displaySpacebetween">
                    <Typography variant="body1">Username</Typography>
                    <IconButton onClick={() => setTipsHistoryOpen(true)}>
                      <GiBackwardTime color="#EB7D2D" />
                    </IconButton>
                  </Box>

                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="username"
                    placeholder="Please enter username"
                    value={values.username}
                    error={Boolean(touched.username && errors.username)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormHelperText error>
                    {touched.username && errors.username}
                  </FormHelperText>
                </Box>
                <Box mt={2} className="endAdormentclx">
                  <Typography variant="body1">Amount</Typography>
                  <Box className="amountBoxClx">
                    <Box width="100%">
                      <TextField
                        variant="outlined"
                        placeholder="Enter the Amount"
                        fullWidth
                        type="text"
                        name="amount"
                        value={values.amount}
                        error={Boolean(touched.amount && errors.amount)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="start"
                                onClick={() =>
                                  setFieldValue("amount", values.amount / 2)
                                }
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "rgba(255, 255, 255, 0.87)",
                                  }}
                                >
                                  1/2
                                </Typography>
                              </IconButton>
                              <Box
                                style={{
                                  width: "20px",
                                }}
                              ></Box>
                              <IconButton
                                edge="start"
                                style={{
                                  borderLeft: "2px solid rgb(101, 101, 101)",
                                  paddingRight: "16px",
                                  borderRadius: "0px",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "rgba(255, 255, 255, 0.87)",
                                  }}
                                  onClick={() =>
                                    setFieldValue("amount", values.amount * 2)
                                  }
                                >
                                  2X
                                </Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.amount && errors.amount}
                      </FormHelperText>
                    </Box>
                    {/* <IconButton className="iconButton">
                      <Typography
                        variant="body1"
                        style={{ color: "rgba(255, 255, 255, 0.87)" }}
                      >
                        USD
                      </Typography>
                    </IconButton> */}
                  </Box>
                </Box>

                <Box mt={2} align="start">
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    placeholder="Please enter password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="center">
                          <IconButton edge="center" style={{ color: "#fff" }}>
                            <RiLockPasswordFill />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box className="displaySpacebetween" mt={0.3}>
                    <FormHelperText error>
                      {touched.password && errors.password}
                    </FormHelperText>
                  </Box>
                </Box>
                <Box mt={2} className="balanceAvaiclx ">
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Balance available for Tip
                    </Typography>
                    <span>
                      {auth?.userData?.balance
                        ? setCryptoDecimals(auth?.userData?.balance)
                        : "0"}{" "}
                      TRX
                    </span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">Amount to Tip</Typography>
                    <span>{values.amount ? values.amount : "0"} TRX</span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Total remaining balance
                    </Typography>
                    <span>
                      {setCryptoDecimals(
                        auth?.userData?.balance - values.amount
                      )}{" "}
                      TRX
                    </span>
                  </Box>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    Send Tip {isLoading && <LoadingComp />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <ForgotPasswordModal
        setForgotModalOpen={setForgotModalOpen}
        confirmModal={forgotModalOpen}
        setLoginModalOpen={setForgotModalOpen}
      />
      <TipsHistoryModal
        confirmModal={tipsHistoryOpen}
        handleClose={() => setTipsHistoryOpen(false)}
      />
    </>
  );
}
