import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  Hidden,
  Button,
  useTheme,
} from "@material-ui/core";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import NavItem from "./NavItem";
import { MdHome } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import { HiOutlineCodeBracket } from "react-icons/hi2";
import { GiCash, GiWallet } from "react-icons/gi";
import { VscGraph } from "react-icons/vsc";
import { BiSolidHelpCircle } from "react-icons/bi";
import "react-pro-sidebar/dist/css/styles.css";
import {
  AiFillTrophy,
  AiOutlineSetting,
  AiOutlineLogout,
} from "react-icons/ai";
import { AuthContext } from "../../../context/Auth";
import { setCryptoDecimals } from "../../../utils";
import clsx from "clsx";
import { FaUserAlt, FaUserFriends, FaUsers } from "react-icons/fa";
import { RiSecurePaymentFill } from "react-icons/ri";

const sections = [
  {
    items: [
      {
        title: "HOME",
        href: "/",
        icon: MdHome,
      },
      {
        title: "ACCOUNT",
        icon: HiOutlineUser,
        items: [
          {
            title: "OVERVIEW",
            href: "/overview",
          },
          {
            title: "STATS",
            href: "/stats",
          },
          {
            title: "SECURITY",
            href: "/security",
          },
        ],
      },

      {
        title: "CASHIER",
        icon: GiCash,
        items: [
          {
            title: "DEPOSIT",
            href: "/deposit",
          },
          {
            title: "WITHDRAWAL",
            href: "/withdrawal",
          },
          {
            title: "TIPS",
            href: "/tips",
          },
        ],
      },
      // {
      //   title: "BANKROLL",
      //   icon: BsBank2,
      //   items: [
      //     {
      //       title: "OVERVIEW",
      //       href: "/bankoverview",
      //     },
      //     {
      //       title: "CHANGE BANKROLL",
      //       href: "/changebankroll",
      //     },
      //     {
      //       title: "HISTORY",
      //       href: "/bankhistory",
      //     },
      //   ],
      // },
      // {
      //   title: "BACKTESTING",
      //   href: "/backtesting",
      //   icon: HiOutlineCodeBracket,
      // },
      {
        title: "STATS",
        href: "/statistics",
        icon: VscGraph,
      },
      {
        title: "LEADERBOARD",
        href: "/leaderboard",
        icon: AiFillTrophy,
      },
      {
        title: "HELP",
        href: "/help",
        icon: BiSolidHelpCircle,
      },
      // {
      //   title: "SETTING",
      //   href: "/setting",
      //   icon: AiOutlineSetting,
      // },
      {
        title: "LOGOUT",
        href: "/logout",
        icon: AiOutlineLogout,
      },
    ],
  },
];

const adminSections = [
  {
    items: [
      {
        title: "DASHBOARD",
        href: "/admin-dashboard",
        icon: MdHome,
      },
      {
        title: "USER MANAGEMENT",
        href: "/user-management",
        icon: FaUserAlt,
      },
      {
        title: "WALLET MANAGEMENT",
        href: "/withdraw-management",
        icon: GiWallet,
      },
      // {
      //   title: "SUB-ADMIN MANAGEMENT",
      //   href: "/sub-adminManagement",
      //   icon: FaUserFriends,
      // },
      // {
      //   title: "CASHIER MANAGEMENT",
      //   icon: GiCash,
      //   items: [
      //     {
      //       title: "DEPOSIT",
      //       href: "/deposit-management",
      //     },
      //     {
      //       title: "WITHDRAWAL",
      //       href: "/withdrawal-management",
      //     },
      //     {
      //       title: "TIPS",
      //       href: "/tips-management",
      //     },
      //   ],
      // },
      {
        title: "FEE MANAGEMENT",
        href: "/fee-management",
        icon: RiSecurePaymentFill,
      },
      {
        title: "STATIC CONTENT",
        href: "/static-management",
        icon: MdHome,
      },
      {
        title: "FAQ MANAGEMENT",
        href: "/faq-management",
        icon: MdHome,
      },
      // {
      //   title: "SETTING",
      //   icon: GiCash,
      //   items: [
      //     {
      //       title: "EDIT PROFILE",
      //       href: "/edit-profile",
      //     },
      //     {
      //       title: "CHANGE PASSWORD",
      //       href: "/change-password",
      //     },
      //   ],
      // },
      {
        title: "LOGOUT",
        href: "/logout",
        icon: AiOutlineLogout,
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <>
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    </>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    // width: 270,
    backgroundColor: "#26272B",
  },
  desktopDrawer: {
    top: "95px",
    borderRadius: "5px",
    width: "250px",
    height: "calc(100% - 123px) !important",
    marginLeft: "18px",
    background: "#26272B",
    backdropFilter: "blur(3.22162px)",
  },
  walletBalanceBox: {
    border: "2px solid #303337",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    "& p": {
      padding: "7px",
      color: "#fff",
    },
    "& img": {
      height: "21px",
    },
    "@media(min-width:600px)": {
      display: "none",
    },
  },
  buttonBox: {
    backgroundColor: "#EB7D2D",
    padding: "10px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },

  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    // width: drawerWidth,
    top: "85px",
    borderRadius: "5px",
    height: "calc(100vh - 123px) !important",
    marginLeft: "18px",
    background: "#26272B",
    overflowX: "hidden",
    // transitionTimingFunction:"ease-in",
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
    },

    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    top: "95px",
    borderRadius: "5px",
    height: "calc(100vh - 123px) !important",
    marginLeft: "18px",
    background: "#26272B",
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
    },

    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawarClx: {
    background: "#26272B",
    width: "240px",
    height: "calc(100vh - 123px)",
    borderRadius: "5px",
    "@media(max-width:1280px)": {
      height: "100% !important",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [account, setAccount] = useState(false);
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      auth.setOpenDrower(true);
    }
  }, [openMobile, onMobileClose]);

  const navBarMenuData = useMemo(() => {
    const menu =
      sections && sections[0].items.filter((x) => x.title !== "LOGOUT");

    const adminControllsCheck =
      auth?.userData?.userType === "ADMIN" ? adminSections : sections;
    return auth.userLoggedIn ? adminControllsCheck : [{ items: menu }];
  }, [auth, location.pathname]);

  // useEffect(() => {
  //   if (auth?.userData?.userType === "ADMIN") {
  //     window.open("/admin-dashboard", "_self");
  //   }
  // }, [auth?.userData?.userType]);

  const content = (
    <>
      <Box
        onClick={() => auth.setOpenDrower(!auth.openDrower)}
        className="newdrawBox"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          style={{ marginLeft: "24px" }}
        >
          <path
            d="M18.8156 8.75995C18.6961 9.03121 18.6146 9.32902 18.4514 9.56803C18.0986 10.0821 17.577 10.3116 16.9567 10.3249C16.9206 10.3249 16.8827 10.3249 16.8466 10.3249C11.8862 10.3249 6.92581 10.323 1.96728 10.3268C1.45133 10.3268 0.996067 10.1864 0.599613 9.84498C-0.183811 9.17347 -0.183811 7.82856 0.599613 7.15896C0.975201 6.83838 1.4039 6.67904 1.89331 6.67904C6.90304 6.67904 11.9109 6.67714 16.9206 6.68093C17.8786 6.68093 18.5671 7.24811 18.7853 8.18139C18.791 8.20415 18.8062 8.22312 18.8156 8.24399C18.8156 8.41661 18.8156 8.58922 18.8156 8.75995Z"
            fill="white"
          />
          <path
            d="M18.8156 2.07144C18.7189 2.39961 18.6165 2.72208 18.385 2.98955C18.0379 3.3898 17.6016 3.60415 17.0762 3.64588C17.0155 3.65157 16.9529 3.64967 16.8922 3.64967C11.8995 3.64967 6.90684 3.64967 1.91607 3.64967C1.13644 3.64967 0.554087 3.31202 0.187984 2.61775C-0.301419 1.69396 0.20885 0.432513 1.20852 0.125214C1.44943 0.0512344 1.7112 0.0132962 1.96349 0.0113993C6.92581 0.00570858 11.8862 0.01709 16.8485 1.78478e-05C17.7951 -0.00377597 18.6222 0.597544 18.7891 1.52703C18.7929 1.54979 18.8062 1.57256 18.8156 1.59342C18.8156 1.75276 18.8156 1.9121 18.8156 2.07144Z"
            fill="white"
          />
          <path
            d="M18.8156 15.4124C18.7227 15.769 18.6032 16.1124 18.3433 16.3912C18.0019 16.7554 17.5864 16.9641 17.0857 16.9944C17.0193 16.9982 16.951 16.9982 16.8827 16.9982C11.8957 16.9982 6.91063 16.9963 1.92366 17.0001C1.28629 17.0001 0.772232 16.7725 0.371984 16.2755C-0.375399 15.3498 0.0627876 13.8437 1.19524 13.4795C1.43046 13.4036 1.68654 13.3618 1.93124 13.3618C6.91063 13.3543 11.8919 13.3618 16.8713 13.3524C17.797 13.3505 18.6184 13.9461 18.7853 14.8889C18.7891 14.9059 18.8024 14.9192 18.8118 14.9363C18.8156 15.0937 18.8156 15.2531 18.8156 15.4124Z"
            fill="white"
          />
        </svg>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.drawarClx}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box mt={3} mb={12}>
            <Box width="100%" className="displayCenter">
              {auth?.userData?.balance && (
                <Box className={classes.walletBalanceBox}>
                  <Typography variant="body2">
                    {auth?.userData?.balance
                      ? setCryptoDecimals(auth?.userData?.balance)
                      : "0"}
                  </Typography>
                  &nbsp;
                  <img src="images/trx.png" alt="image" />
                  &nbsp;
                  <Box className={`${classes.buttonBox} displayCenter`}>
                    Wallet
                  </Box>
                </Box>
              )}
            </Box>
            {navBarMenuData?.map((section, i) => (
              <List
                style={{ margin: "0px 8px" }}
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                })}
              </List>
            ))}
          </Box>
        </PerfectScrollbar>
      </Box>
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        {/* <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer> */}

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: auth.openDrower,
            [classes.drawerClose]: !auth.openDrower,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: auth.openDrower,
              [classes.drawerClose]: !auth.openDrower,
            }),
          }}
        >
          {content}
        </Drawer>

        {/* <NewItemCompo sections={navBarMenuData} /> */}
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
