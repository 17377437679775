import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { CgClose } from "react-icons/cg";
import GraphComponent from "./GraphComponent";
import { VscGraph } from "react-icons/vsc";
import { getAPIHandler } from "../ApiConfig/service";
import Axios from "axios";
import { AuthContext } from "../context/Auth";
import { generateUniqueAvatar } from "../utils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthMd": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 22px",
      },
    },
    "& .userBox": {
      background: "#1D1F23",
      padding: "10px",
      margin: "14px 0px",
      "& .borderClx": {
        border: "1px dotted #EB7D2D",
        padding: "2px",
        borderRadius: "50%",
      },
      "& h6": {
        fontSize: "16px",
      },
      "& p": {
        fontSize: "12px",
      },
    },

    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
  tableBoxData: {
    padding: "12px",
    background: "#1D1F23",
    "& .contentData": {
      padding: "3px 0px",
      "& p": {
        fontSize: "14px",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "300px",
        color: "#EB7D2D",
        marginTop: "4px",
      },
    },
    "& .borderClxText": {
      borderBottom: "1px solid #484949",
    },
  },
  graphBox: {
    background: "#1D1F23",
    textAlign: "center",
    "& h6": {
      paddingBottom: "16px",
      marginTop: "-12px",
    },
    "& .displayCenter": {
      justifyContent: "none",
      "& h6": {
        transform: " rotate(-90deg)",
        width: "37px",
        padding: "0px",
        marginTop: "2px",
      },
    },
  },
}));

export default function StatsModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { userData } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState();
  const [graphData, setGraphData] = useState();
  console.log("statsDatastatsData", statsData);
  console.log("graphData", graphData);
  const getPlatformStats = async (source) => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "stats",
        source: source,
      });
      console.log("responseresponse", response);
      if (response.data?.statusCode == 200) {
        setStatsData(response?.data?.responseResult);
        setGraphData(response?.data?.responseResult?.graphRes);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (confirmModal) {
      getPlatformStats(source);
    }
    return () => {
      source.cancel();
    };
  }, [confirmModal]);
  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="md"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: 0, top: 0 }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <Box className="displayStart">
        <VscGraph />
        &nbsp;&nbsp;
        <Typography variant="h5">STATS</Typography>
      </Box>

      <Box className="userBox tabDisplayBox">
        <Box className="borderClx">
          <Avatar
            src={generateUniqueAvatar(userData?.address)}
            alt="profile_img"
          />
        </Box>
        &nbsp;&nbsp;
        <Box>
          <Typography variant="h6">{userData && userData?.username}</Typography>
          <Typography variant="body1">
            {`JOINED: ${userData?.dateOfJoining}`} [
            {userData?.createdAt &&
              moment(userData?.createdAt).startOf("hour").fromNow()}
            ]
          </Typography>
        </Box>
      </Box>

      <Typography variant="h6">PERFORMANCE</Typography>
      <DialogContent>
        <Box className={classes.tableBoxData}>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Games Played</Typography>
            <Typography variant="h6">
              {statsData?.totalBets ? statsData?.totalBets : "--"}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween borderClxText">
            <Typography variant="body1">Total Wagered</Typography>
            <Typography variant="h6">
              {`${statsData?.wagerAmount ? statsData?.wagerAmount : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Net Profit</Typography>
            <Typography variant="h6">
              {`${statsData?.totalProfit ? statsData?.totalProfit : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Profit All Time High</Typography>
            <Typography variant="h6">
              {`${statsData?.highProfit ? statsData?.highProfit : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Profit All Time Low</Typography>
            <Typography variant="h6">
              {`${statsData?.lowProfit ? statsData?.lowProfit : "--"} TRX`}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.graphBox} mt={2}>
          <Box className="displayCenter">
            <Typography variant="h6">PROFIT</Typography>
            <GraphComponent data={graphData} isLoading={isLoading} />
          </Box>
          <Typography variant="h6">TIME</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
