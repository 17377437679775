import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "../ApiConfig/ApiConfig";
import { getAPIHandler } from "../ApiConfig/service";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const sessionTimeout = 24 * 60 * 60 * 1000;
    setTimeout(() => {
      logout();
    }, sessionTimeout);
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

const logout = () => {
  sessionStorage.removeItem("token");
  delete axios.defaults.headers.common.Authorization;
};

const logoutAfterTimeout = () => {
  const sessionTimeout = 24 * 60 * 60 * 1000;
  setTimeout(() => {
    logout();
    window.location.href = "/";
  }, sessionTimeout);
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [openDrower, setOpenDrower] = useState(true);
  const [trxUsdtPrice, setTrxUsdtPrice] = useState([]);

  const getUserProfile = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: ApiConfig.getUserProfile,
        headers: {
          token: window.sessionStorage.getItem("token")
            ? window.sessionStorage.getItem("token")
            : token,
        },
      });
      if (response.data.statusCode === 200) {
        setUserData(response?.data?.responseresult);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLivePriceCoin = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,tether,tron&order=market_cap_desc&price_change_percentage=1h%2C24h",
      });
      if (response.status === 200) {
        setTrxUsdtPrice(
          response?.data?.filter((item, i) => item?.symbol == "trx")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfile(window.sessionStorage.getItem("token"));
      getLivePriceCoin();
      logoutAfterTimeout();
    }
  }, [window.sessionStorage.getItem("token")]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    setOpenDrower,
    openDrower,
    trxUsdtPrice,
    getUserProfile: getUserProfile,
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
