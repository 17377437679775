import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Typography,
  Hidden,
  Avatar,
  Button,
  IconButton,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import CreateAccountModal from "../../component/CreateAccountModal";
import LoginModal from "../../component/LoginModal";
import Logo from "../../component/Logo";
import { AuthContext } from "../../context/Auth";
import { setCryptoDecimals } from "../../utils";
import { useHistory } from "react-router-dom";
import OverViewModal from "../../component/OverViewModal";
import { BiBell } from "react-icons/bi";
import NotificationModal from "../../component/NotificationModal";
import CashHistoryModal from "../../component/CashHistoryModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  buttonBoxes: {
    display: "flex",
    alignItems: "center",
    "& h6": {
      cursor: "pointer",
    },
    "& .avatar": {
      cursor: "pointer",
      width: 20,
      height: 20,
    },
    "& .loginbtn": {
      // border: "none",
      // backgroundColor: "transparent",
      // boxShadow: "none",
      // padding: "10px 39px 10px 0px",
      // [theme.breakpoints.down("xs")]: {
      //   padding: "0px 9px 7px 0px",
      // },
      // "&:hover": {
      //   color: "#EB7D2D",
      // },
      padding: "7px 35px",
      marginRight: "8px",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#EB7D2D",
        backgroundBlendMode: "overlay",
        color: "#fff",
        borderRadius: "5px",
        whiteSpace: "pre",
        fontSize: "14px",
        padding: "7px 35px",
        fontWeight: "500",
      },
    },
  },
  mainTopbar: {
    display: " flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& .walletBalanceBox": {
      border: "2px solid #303337",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      "& p": {
        padding: "7px",
        color: "#fff",
      },
      "& img": {
        height: "21px",
      },
    },
  },
  buttonBox: {
    backgroundColor: "#EB7D2D",
    padding: "10px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    cursor: "pointer",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [overViewModal, setOverViewModal] = useState(false);
  const [openNotificationModal, setNotificationModal] = useState(false);
  const history = useHistory();
  // "https://min-api.cryptocompare.com/data/price?fsym=TRX&tsyms=" +
  // 'USD' +
  // "&api_key=" +
  // "fab4eccf036f6b870af1c13ae652b23259186cb5ef0ce00ae62df236ba42d719"

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box className={classes.mainTopbar}>
      <Logo />
      {auth?.userData?.userType !== "ADMIN" && auth?.userLoggedIn && (
        <Hidden xsDown>
          <Box className="walletBalanceBox">
            <Typography variant="body2">
              {auth?.userData?.balance
                ? auth?.userData?.balance.toFixed(2)
                : "0"}
            </Typography>
            &nbsp;
            <img src="images/trx.png" alt="" />
            &nbsp;
            {auth?.userLoggedIn && (
              <Box
                className={`${classes.buttonBox} displayCenter`}
                onClick={() => setOverViewModal(true)}
              >
                Wallet
              </Box>
            )}
            {/* <Box className={`${classes.buttonBox} displayCenter`}>Wallet23</Box> */}
          </Box>
        </Hidden>
      )}

      <Box className={classes.buttonBoxes}>
        {window.sessionStorage.getItem("token") ? (
          <>
            {auth?.userData?.userType !== "ADMIN" && (
              <Box
                style={{ marginRight: "8px", cursor: "pointer" }}
                className="displayStart"
              >
                <BiBell
                  color="#EB7D2D"
                  fontSize="22px"
                  onClick={() => setNotificationModal(true)}
                />
              </Box>
            )}

            <Box
              style={{ marginRight: "8px" }}
              onClick={
                auth?.userData?.userType !== "ADMIN"
                  ? () => setOverViewModal(true)
                  : ""
              }
            >
              <Avatar
                src={
                  auth?.userData?.profilePic
                    ? auth?.userData?.profilePic
                    : "images/profile.png"
                }
                className="avatar"
                alt={auth?.userData?.username}
              />
            </Box>
            <Typography
              variant="h6"
              onClick={
                auth?.userData?.userType !== "ADMIN"
                  ? () => setOverViewModal(true)
                  : ""
              }
            >
              {auth?.userData?.username ? auth?.userData?.username : "..."}
            </Typography>
          </>
        ) : (
          <>
            <Button
              style={{ padding: "7px 20px" }}
              variant="outlined"
              color="secondary"
              onClick={() => setLoginModalOpen(true)}
              className="loginbtn"
            >
              LOGIN
            </Button>
            <Button
              style={{ padding: "10px 20px" }}
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              REGISTER
            </Button>
          </>
        )}
        {/* {auth?.userData?.userType === "ADMIN" && (
          <Box ml={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open("/withdraw-management", "_self")}
            >
              Admin Control
            </Button>
          </Box>
        )} */}
      </Box>
      <CreateAccountModal
        confirmModal={modalOpen}
        setLoginModalOpen={setLoginModalOpen}
        setModalOpen={setModalOpen}
      />
      <LoginModal
        setLoginModalOpen={setLoginModalOpen}
        confirmModal={loginModalOpen}
        setModalOpen={setModalOpen}
      />
      {overViewModal && (
        <OverViewModal
          handleClose={() => setOverViewModal(false)}
          confirmModal={overViewModal}
        />
      )}
      {openNotificationModal && (
        <NotificationModal
          handleClose={() => setNotificationModal(false)}
          confirmModal={openNotificationModal}
        />
      )}
    </Box>
  );
}
