import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { GiBackwardTime } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
  },
}));

const tableHead = [
  {
    heading: "TIME",
  },
  {
    heading: "BANKROLL CHANGE",
  },
];
export default function BankRollHistory({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [noOfTotalPages, setNoOfTotalPages] = useState("");

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <GiBackwardTime color="#EB7D2D" />
          &nbsp;&nbsp;
          <Typography variant="h5">BANKROLL HISTORY</Typography>
        </Box>

        <DialogContent>
          <Box className="mainBox" mt={1.4}>
            <TableComp
              tableHead={tableHead}
              scoreListData={[
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
                {
                  TIME: "12th AUG, 2023",
                  "BANKROLL CHANGE": "xvxsdshgczbgscu2263jcx",
                },
              ]}
              noOfPages={noOfPages}
              noOfTotalPages={noOfTotalPages}
              page={page}
              setPage={setPage}
              limit={10}
              isLoading={isLoading}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
