import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { HiEye, HiEyeOff } from "react-icons/hi";
import ConfirmSignUpModal from "./ConfirmSignUpModal";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
  },
}));

export default function CreateAccountModal({
  setModalOpen,
  confirmModal,
  setLoginModalOpen,
}) {
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPass: false,
  });

  const initialValues = {
    email: "",
    username: "",
    // profession: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),

    username: yup
      .string()
      .required("Username is required.")
      .matches(/^[a-zA-Z0-9_]{3,20}$/, "Please enter a valid username.")
      .min(3, "Please enter atleast 3 characters.")
      .max(32, "You can enter only 32 characters."),

    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
    confirmPassword: yup
      .string()
      .required("Please enter confirm password.")
      .oneOf([yup.ref("password"), null], "Password must match."),
  });

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <IconButton
          style={{ position: "absolute", right: "0px" }}
          onClick={() => setModalOpen(false)}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => setConfirmModalOpen(true)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box align="center" mb={2} p={3} className="dialodcustomBox">
                  <Typography variant="h5">CREATE AN ACCOUNT</Typography>
                  <Box mt={2} align="start">
                    <Typography variant="body1">Email</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="email"
                      name="email"
                      placeholder="Please enter email address"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="images/emailImg.svg" alt="email" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Box>
                  <Box mt={2} align="start">
                    <Typography variant="body1">Username</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="username"
                      placeholder="Please enter username"
                      value={values.username}
                      error={Boolean(touched.username && errors.username)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="images/userImg.png" alt="userImg" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.username && errors.username}
                    </FormHelperText>
                  </Box>
                  {/* <Box mt={2} align="start">
                    <Typography variant="body1">Profession</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="profession"
                      placeholder="Please enter profession"
                      value={values.profession}
                      error={Boolean(touched.profession && errors.profession)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img
                                src="images/professionIcon.svg"
                                alt="profession"
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.profession && errors.profession}
                    </FormHelperText>
                  </Box> */}
                  <Box mt={2} align="start">
                    <Typography variant="body1">Password</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword.password ? "text" : "password"}
                      name="password"
                      placeholder="Please enter password"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="./images/passlock.png" alt="passlock" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                            >
                              {showPassword.password ? (
                                <HiEye className={classes.iconsclass1} />
                              ) : (
                                <HiEyeOff className={classes.iconsclass1} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.password && errors.password}
                    </FormHelperText>
                  </Box>
                  <Box mt={2} align="start">
                    <Typography variant="body1">Confirm Password</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword.confirmPass ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Please enter confirm password"
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="./images/passlock.png" alt="passlock" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confirmPass: !showPassword.confirmPass,
                                })
                              }
                            >
                              {showPassword.confirmPass ? (
                                <HiEye className={classes.iconsclass1} />
                              ) : (
                                <HiEyeOff className={classes.iconsclass1} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.confirmPassword && errors.confirmPassword}
                    </FormHelperText>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    CONTINUE
                  </Button>

                  <Box mt={2} className={classes.mainBox}>
                    <Typography variant="subtitle1">
                      Already have an account?{" "}
                      <span
                        onClick={() => {
                          setModalOpen(false);
                          setLoginModalOpen(true);
                        }}
                      >
                        Log In
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <ConfirmSignUpModal
                  handleClose={() => setConfirmModalOpen(false)}
                  confirmModal={confirmModalOpen}
                  setModalOpen={setModalOpen}
                  setLoginModalOpen={setLoginModalOpen}
                  values={values}
                />
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
