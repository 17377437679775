import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { CgClose, CgPlayListSearch } from "react-icons/cg";
import { postAPIHandler } from "../ApiConfig/service";
import { AuthContext } from "../context/Auth";
import { generateUniqueAvatar, setCryptoDecimals } from "../utils";
import WithdrawalModal from "./WithdrawalModal";
import moment from "moment";
import MakeDepositModal from "./MakeDepositModal";
import TipsHistoryModal from "./TipsHistoryModal";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 28px",
      },
    },
    "& .userBox": {
      background: "#1D1F23",
      padding: "10px",
      margin: "14px 0px",
      "& .borderClx": {
        border: "1px dotted #EB7D2D",
        padding: "2px",
        borderRadius: "50%",
      },
      "& h6": {
        fontSize: "14px",
      },
      "& p": {
        fontSize: "12px",
      },
    },
    "& .contentData": {
      background: "#1D1F23",
      padding: "12px",
      marginTop: "4px",
      "& p": {
        fontSize: "13px",
      },
      "& h6": {
        fontSize: "13px",
        fontWeight: "300px",
        color: "#EB7D2D",
        marginTop: "4px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
  lastElement: {
    background: "rgba(72, 73, 73, 0.30) !important",
    cursor: "pointer",
    "& p": {
      fontSize: "12px",
      fontWeight: "600",
    },
    "& h6": {
      fontSize: "12px",
    },
  },
}));

export default function OverViewModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [depositHistory, setDepositHistory] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [tipsHistoryOpen, setTipsHistoryOpen] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const auth = useContext(AuthContext);
  const { userData } = auth;
  const getOverviewDataHandler = async () => {
    try {
      const response = await postAPIHandler({
        endPoint: "overview",
      });
      if (response.data.statusCode === 200) {
        setOverviewData(response.data.responseResult);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (confirmModal) {
      getOverviewDataHandler();
    }
  }, [confirmModal]);

  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="xs"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: 0, top: 0 }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <Box className="displayStart">
        <CgPlayListSearch />
        &nbsp;&nbsp;
        <Typography variant="h5">OVERVIEW</Typography>
      </Box>

      <Box className="userBox tabDisplayBox">
        <Box className="borderClx">
          <Avatar
            src={generateUniqueAvatar(userData?.address)}
            alt="profile_img"
          />
        </Box>
        &nbsp;&nbsp;
        <Box>
          <Typography variant="h6">{userData && userData?.username}</Typography>
          <Typography variant="body1">
            {`JOINED: ${
              userData?.dateOfJoining ? userData?.dateOfJoining : "..."
            }`}{" "}
            [
            {/* {userData?.createdAt &&
              moment(userData?.createdAt).startOf("hour").fromNow()} */}
            {userData?.createdAt && moment.utc(userData?.createdAt).fromNow()}]
          </Typography>
        </Box>
      </Box>

      <Typography variant="h6">BREAKDOWN</Typography>
      <DialogContent>
        <Box
          className={`contentData displaySpacebetween ${classes.lastElement}`}
          onClick={() => setDepositHistory(true)}
        >
          <Typography variant="body1">Deposit</Typography>
          <Typography variant="h6">
            {overviewData && overviewData.totalDeposite
              ? `${setCryptoDecimals(overviewData.totalDeposite)} TRX`
              : "0 TRX"}
          </Typography>
        </Box>
        <Box
          className={`contentData displaySpacebetween ${classes.lastElement}`}
          onClick={() => setWithdrawModal(true)}
        >
          <Typography variant="body1">Withdrawals</Typography>
          <Typography variant="h6">
            {overviewData && overviewData.totalWithdrawAmount
              ? `${setCryptoDecimals(overviewData.totalWithdrawAmount)} TRX`
              : "0 TRX"}
          </Typography>
        </Box>
        <Box
          className={`contentData displaySpacebetween ${classes.lastElement}`}
          onClick={() => setTipsHistoryOpen(true)}
        >
          <Typography variant="body1">Incoming Tips</Typography>
          <Typography variant="h6">
            {overviewData && overviewData.totalIncomingTips
              ? `${setCryptoDecimals(overviewData.totalIncomingTips)} TRX`
              : "0 TRX"}
          </Typography>
        </Box>
        <Box
          className={`contentData displaySpacebetween ${classes.lastElement}`}
          onClick={() => setTipsHistoryOpen(true)}
        >
          <Typography variant="body1">Outgoing Tips</Typography>
          <Typography variant="h6">
            {overviewData && overviewData.totalOutgoingTips
              ? `${setCryptoDecimals(overviewData.totalOutgoingTips)} TRX`
              : "0 TRX"}
          </Typography>
        </Box>

        <Box
          className={`contentData displaySpacebetween ${classes.lastElement}`}
        >
          <Typography variant="body1">Game Profit</Typography>
          <Typography variant="h6">
            {overviewData && overviewData.gameProfit
              ? `${setCryptoDecimals(overviewData.gameProfit)} TRX`
              : "0 TRX"}
          </Typography>
        </Box>
      </DialogContent>
      {depositHistory && (
        <MakeDepositModal
          handleClose={() => setDepositHistory(false)}
          confirmModal={depositHistory}
        />
      )}

      {withdrawModal && (
        <WithdrawalModal
          handleClose={() => setWithdrawModal(false)}
          confirmModal={withdrawModal}
        />
      )}
      {withdrawModal && (
        <WithdrawalModal
          handleClose={() => setWithdrawModal(false)}
          confirmModal={withdrawModal}
        />
      )}
      {tipsHistoryOpen && (
        <TipsHistoryModal
          confirmModal={tipsHistoryOpen}
          handleClose={() => setTipsHistoryOpen(false)}
        />
      )}
    </Dialog>
  );
}
