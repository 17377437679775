import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import UpdatePasswordModal from "./UpdatePasswordModal";
import UpdateEmailModal from "./UpdateEmailModal";
import DeleteAccountModal from "./DeleteAccountModal";
import TwoFactorAuthentication from "./TwoFactorAuthentication";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },

    "& .contentData": {
      background: "#1D1F23",
      padding: "8px 12px",
      marginTop: "4px",
      "& .displayStart": {
        borderBottom: "0px",
        paddingBottom: "0px",
        "& svg": {
          fontSize: " 15px",
          color: "rgba(255, 255, 255, 0.60)",
        },
        "& img": {
          height: "16px",
        },
      },
      "& .imgClx": {
        "& img": {
          height: "11px !important",
        },
      },
      "& button": {
        padding: "3px 14px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
}));
const arrayBreakDown = [
  {
    transactionName: "Update Password",
    icon: <RiLockPasswordFill style={{ fontSize: "20px" }} />,
    key: "updatePassword",
  },
  {
    transactionName: "Update Email",
    icon: (
      <img src="images/emailImg.svg" alt="email" style={{ fontSize: "20px" }} />
    ),
    key: "updateEmail",
  },
  {
    transactionName: "Two Factor Authentication",
    icon: (
      <img src="images/twoFAicon.svg" alt="" style={{ fontSize: "20px" }} />
    ),
    key: "twoFa",
  },
  {
    transactionName: "Delete Account",
    icon: (
      <img src="images/deleteUser.svg" alt="" style={{ fontSize: "20px" }} />
    ),
    key: "deleteAccount",
  },
];
export default function SecurityModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openSecurityModal, setOpenSecurityModal] = useState({
    updatePassword: false,
    updateEmail: false,
    twoFa: false,
    deleteAccount: false,
  });

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <RiLockPasswordFill />
          &nbsp;&nbsp;
          <Typography variant="h5">SECURITY</Typography>
        </Box>

        <Typography variant="h6" style={{ marginTop: "16px" }}>
          CHOOSE AN OPTION
        </Typography>
        <DialogContent>
          {arrayBreakDown &&
            arrayBreakDown.map((item, i) => {
              return (
                <Box key={i} className="contentData displaySpacebetween">
                  <Box
                    className={`displayStart ${
                      item.transactionName === "Update Email" && "imgClx"
                    }`}
                  >
                    <span style={{ fontSize: "20px" }}> {item.icon}</span>
                    &nbsp;&nbsp;
                    <Typography
                      style={{ fontSize: "14px" }}
                      variant="subtitle1"
                    >
                      {item.transactionName}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      setOpenSecurityModal((prevState) => {
                        const updatedState = {};
                        arrayBreakDown.forEach((itm) => {
                          updatedState[itm.key] = itm.key === item.key;
                        });
                        return updatedState;
                      });
                    }}
                  >
                    {item?.transactionName === "Delete Account"
                      ? "DELETE"
                      : "UPDATE"}
                  </Button>
                </Box>
              );
            })}
        </DialogContent>
      </Dialog>
      {openSecurityModal.updatePassword && (
        <UpdatePasswordModal
          handleClose={() =>
            setOpenSecurityModal({
              ...openSecurityModal,
              updatePassword: false,
            })
          }
          confirmModal={openSecurityModal.updatePassword}
          closeSecurityModal={handleClose}
        />
      )}
      {openSecurityModal.updateEmail && (
        <UpdateEmailModal
          handleClose={() =>
            setOpenSecurityModal({ ...openSecurityModal, updateEmail: false })
          }
          confirmModal={openSecurityModal.updateEmail}
          closeSecurityModal={handleClose}
        />
      )}
      {openSecurityModal.twoFa && (
        <TwoFactorAuthentication
          handleClose={() =>
            setOpenSecurityModal({ ...openSecurityModal, twoFa: false })
          }
          confirmModal={openSecurityModal.twoFa}
          closeSecurityModal={handleClose}
        />
      )}
      {openSecurityModal.deleteAccount && (
        <DeleteAccountModal
          handleClose={() =>
            setOpenSecurityModal({ ...openSecurityModal, deleteAccount: false })
          }
          confirmModal={openSecurityModal.deleteAccount}
          closeSecurityModal={handleClose}
        />
      )}
    </>
  );
}
