import { Box, makeStyles, Paper } from "@material-ui/core";

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NoDataFound from "./NoDataFound";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  graphboxMain: {
    width: "100%",
    marginLeft: "-16px",
    "& .apexcharts-svg.apexcharts-zoomable.hovering-zoom": {
      cursor: "pointer",
    },
    "& .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker":
      {
        display: "none !important",
      },
    "& .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title": {
      display: "none !important",
    },
    "& .apexcharts-tooltip-text-y-label": {
      display: "none",
    },
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      display: "flex",
      background: "#40556ec2 !important",
      color: "white !important",
      borderRadius: "9.75949px !important",
      textAligns: "center",
    },
    "& .apexcharts-xaxistooltip": {
      display: "none",
    },
    "& .apexcharts-toolbar": {
      display: "none !important",
    },
  },

  graphBox: {
    position: "relative",
    "& .loaderBox": {
      position: "absolute",
      background: "#00000038",
      width: "100%",
      height: "100%",
      top: "0",
      right: "0",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
      alignItems: "center",
    },
  },
}));

const GraphComponent = ({ isLoading, data }) => {
  console.log(">>>>>data>>>>", data);
  const classes = useStyles();
  const [oneToThirtyArray, setOneToThirtyArray] = useState([]);
  const [grapData, setGrapData] = useState();

  const generateNumberObjectsArray = (start, end) => {
    const numberArray = Array.from(
      { length: end - start + 1 },
      (_, index) => start + index
    );

    const numberObjectsArray = numberArray.map((number) => ({
      value: number,
    }));
    console.log(numberObjectsArray, "oneToThirtyArray");
    return numberObjectsArray;
  };

  useEffect(() => {
    const resultArray = generateNumberObjectsArray(1, data?.length);
    setOneToThirtyArray(resultArray);
  }, []);

  const seriesData = {
    series: [
      {
        data: data && data?.map((item) => `${item?.winAmount}TRX`),
      },
    ],
  };
  const optionsData = {
    options: {
      chart: {
        zoom: {
          enabled: false,
          autoScaleYaxis: false,
        },
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        enabled: false,
        style: {
          colors: ["#EB7D2D"],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        overwriteCategories:
          data &&
          data?.map((value, index) =>
            moment(value?.createdAt).format("DD-MM-YYYY")
          ),
        labels: {
          trim: false,
          style: {
            colors: "#fff",
            fontSize: "12px", // Set font size
            fontWeight: 300, // Set font weight
          },
        },
      },
      yaxis: {
        labels: {
          trim: false,
          style: {
            colors: "#fff",
            fontSize: "12px", // Set font size
            fontWeight: 300, // Set font weight
          },
        },
      },
      tooltip: {
        x: {
          format: "yyyy/dd/mm",
        },
      },
      grid: {
        show: true,
        borderColor: "rgba(72, 73, 73, 0.30)",
        strokeDashArray: 0,
      },
      colors: ["#EB7D2D"],
    },
  };
  return (
    <Paper elevation={0} className={classes.graphboxMain}>
      <Box style={{ width: "100%" }}>
        <Box className={classes.graphBox}>
          <ReactApexChart
            options={optionsData.options}
            series={seriesData.series}
            type="area"
            height={260}
          />
          {isLoading && <NoDataFound />}
        </Box>
      </Box>
    </Paper>
  );
};

export default GraphComponent;
