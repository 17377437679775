import React from "react";
import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import TopTradingSkeleton from "../Skeletons/TopTradingSkeleton";
import NoDataFound from "./NoDataFound";
const useStyles = makeStyles((theme) => ({
  tableBox: {
    // minWidth: "800px",
  },
  mainBox: {
    "& .MuiTableContainer-root": {
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
  tableContainer: {
    "& .MuiIconButton-root": {
      color: "#fff",
      padding: "1px",
      marginRight: "20px",
    },
  },
  rowOdd: {
    background: "#222327",
  },
  rowEven: {
    background: "#282a2e",
  },
}));
function TableComp({
  tableHead,
  scoreListData,
  noOfPages,
  page,
  setPage,
  isLoading,
  limit,
  noOfTotalPages,
  classTable,
  NoDataFoundText,
  type
}) {
  console.log(">>>>>>scoreListData", scoreListData);
  const classes = useStyles();
  const tableClass = classTable ? classTable : classes;

  return (
    <Box className={classes.mainBox}>
      <TableContainer className={tableClass.tableContainer}>
        <Table className={tableClass.tableBox}>
          <TableHead>
            <TableRow>
              {tableHead &&
                tableHead?.map((head, index) => {
                  return <TableCell>{head?.heading}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {scoreListData &&
              scoreListData.map((data, i) => (
                <TableRow
                  key={i}
                  className={
                    i % 2 === 0 ? tableClass.rowOdd : tableClass.rowEven
                  }
                >
                  {tableHead &&
                    tableHead?.map((head, index) => {
                      return (
                        <TableCell key={index}>
                          {head?.heading === "Action" ||
                            head?.heading === "Reward Breakdown" ||
                            head?.heading === "Details"
                            ? data[head?.heading]?.map((action, idx) => (
                              <IconButton
                                key={idx}
                                onClick={action?.onClick}
                                disabled={!action?.icon}
                              >
                                {action?.icon}
                              </IconButton>
                            ))
                            : data[head?.heading] || "..."}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <TopTradingSkeleton skeleton={tableHead} />}
      {type !== "hashData" && (
        <>
          {!isLoading && scoreListData && scoreListData?.length === 0 && (
            <NoDataFound text={NoDataFoundText} />
          )}
        </>

      )}

      {!isLoading && noOfTotalPages > (page === 1 ? limit : 0) && (
        <Box my={2} display="flex" justifyContent="flex-end">
          <Pagination
            count={noOfPages}
            page={page}
            onChange={(e, value) => setPage(value)}
            variant="rounded"
          />
        </Box>
      )}
    </Box>
  );
}

export default TableComp;
