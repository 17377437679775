import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  Box,
  Container,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { TopBarData } from "../../../layouts/HomeLayout/TopBarData";
import Logo from "../../../component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.secondary.main,
  },
  toolbar: {
    padding: "10px 10px",
    backgroundColor: "#1D1F23",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menusec: {
    height: "21px !important",
    width: "21px !important",
    //     "& svg:"{
    // color:"#fff",
    //     },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="xl" style={{ display: "flex" }}>
          <Hidden lgUp>
            <IconButton
              color="#FFCA64"
              onClick={onMobileNavOpen}
              style={{ marginRight: "10px", padding:"0px" }}
            >
              <SvgIcon fontSize="small" className={classes.menusec}>
                <MenuIcon style={{ color: "#FFCA64" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <TopBarData />
        </Container>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
