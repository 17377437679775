import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue } from "../utils";
import { toast } from "react-hot-toast";
import { BsBank2 } from "react-icons/bs";
import ChangeRollConfirmation from "./ChangeRollConfirmation";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .nodeBox": {
      border: "2px solid #DF1104",
      background: "#332528",
      padding: "12px",
      borderRadius: "5px",
      color: "#fff",
      "& span": {
        color: "#EB7D2D",
      },
    },
    "& .amountBoxClx": {
      display: "flex",
      width: "100%",
      gap: "8px",
    },
    "& .balanceAvaiclx": {
      background: "#1D1F23",
      padding: "12px 16px",
      borderRadius: "5px",
      fontSize: "11px",
      lineHeight: "24px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "11px",
      },
      "& p": {
        fontSize: "11px",
      },
    },
  },
}));

export default function ChangeBankRollModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationChange, setConfirmationChange] = useState(false);

  const initialValues = {
    changetrx: "",
  };

  const validationSchema = yup.object().shape({
    changetrx: yup
      .string()
      .trim()
      .required("Please enter change.")
      .max(8, "You can enter only 8 characters."),
  });

  const handleSubmitDataFun = async (values) => {
    setConfirmationChange(true);
  };
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <BsBank2 />
          &nbsp;&nbsp;
          <Typography variant="h5">CHANGE BANKROLL</Typography>
        </Box>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitDataFun(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={1}>
                  <Typography variant="subtitle1">
                    Use positive values to add to the bankroll and negative
                    values to remove from it. If you attempt to invest or divest
                    more than you can afford, the largest possible value is used
                    instead.
                    <br />
                    Up to 2 % of your investment is deducted as a non-refundable
                    dilution fee. This fee is deducted from your bankroll
                    investment.
                  </Typography>
                  <Typography variant="body1">
                    Only invest or divest if you fully understand the
                    consequences.
                  </Typography>
                </Box>

                <Box mt={2} className="endAdormentclx">
                  <Typography variant="body1">Change</Typography>
                  <Box className="amountBoxClx">
                    <Box width="100%">
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="changetrx"
                        value={values.changetrx}
                        error={Boolean(touched.changetrx && errors.changetrx)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="start"
                                onClick={() => toast.success("Copied")}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "rgba(255, 255, 255, 0.87)",
                                  }}
                                >
                                  TRX
                                </Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.changetrx && errors.changetrx}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>

                <Box mt={2} className="balanceAvaiclx ">
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">Your current stake</Typography>
                    <span>0%</span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Estimated bankroll change
                    </Typography>
                    <span>0 TRX</span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Estimated dilution fee
                    </Typography>
                    <span>0 TRX</span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Estimated stake after investing
                    </Typography>
                    <span>0%</span>
                  </Box>
                  <Box className="displaySpacebetween">
                    <Typography variant="body1">
                      Estimated bankroll after investing
                    </Typography>
                    <span>0 TRX</span>
                  </Box>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    INVEST/DIVEST {isLoading && <LoadingComp />}
                  </Button>
                </Box>
                <Box className="nodeBox" mt={2}>
                  <Typography variant="subtitle1">
                    <img src="images/explanationMark.svg" />
                    &nbsp; <span>Tip:</span>&nbsp; *Adds as much as possible and
                    -* removes as much as possible.
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <ChangeRollConfirmation
        handleClose={() => setConfirmationChange(false)}
        confirmModal={confirmationChange}
      />
    </>
  );
}
