import React, { useContext } from "react";

import { AuthContext } from "../context/Auth";

export default function AuthGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);
  if (!auth.userLoggedIn) {
    return window.open("/", "_self");
  }

  return <>{children}</>;
}
