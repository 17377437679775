import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue } from "../utils";
import { toast } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { getAPIHandler, postAPIHandler } from "../ApiConfig/service";
import { AuthContext } from "../context/Auth";
import QRCode from "qrcode.react";
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .imgBox": {
      "& img": {
        width: "144px",
      },
    },
    "& .nodeBox": {
      border: "2px solid #DF1104",
      background: "#332528",
      padding: "12px",
      borderRadius: "5px",
      "& subtitle1": {
        fontWeight: 500,
      },
    },
    "& .enableClx": {
      marginTop: "16px",
      alignItems: "start",
      "& button": {
        borderRadius: "8px",
        padding: "5px 7px",
        color: "white",
      },
    },
  },
}));
export default function TwoFactorAuthentication({
  handleClose,
  confirmModal,
  closeSecurityModal,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { userData } = auth;
  console.log("userDatauserData", auth);
  const [isLoading, setIsLoading] = useState(false);
  const [twoFaCodeData, setTwoFaCodeData] = useState();
  console.log("twoFaCodeDatatwoFaCodeData", twoFaCodeData?.url);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const initialValues = {
    twoFaCode: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    twoFaCode: yup
      .string()
      .required("Please enter 2FA code.")
      .matches(/^[0-9]{6}$/, "Code must be exactly 6 digits."),
    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
  });

  const getTwoFaCode = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "twoFA",
      });
      if (response?.data?.statusCode === 200) {
        setTwoFaCodeData(response?.data?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  const handleSubmitTwoFa = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "verifyTwoFA",
        dataToSend: {
          secret: twoFaCodeData?.secret ? twoFaCodeData?.secret : undefined,
          code: values.twoFaCode ? values.twoFaCode : undefined,
          password: values.password ? values.password : undefined,
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        auth.getUserProfile(sessionStorage.getItem("token"));
        closeSecurityModal();
        handleClose();
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  useEffect(() => {
    getTwoFaCode();
  }, []);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <img src="images/twoFactorAuth.svg" />
          &nbsp;&nbsp;
          <Typography variant="h5">TWO-FACTOR AUTHENTICATION</Typography>
        </Box>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitTwoFa(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box className="enableClx displaySpacebetween">
                  <Typography variant="subtitle1">
                    1. Scan the QR code or enter the secret manually in your
                    authenticator app.
                    <br />
                    2. Click submit, then enter the 2FA code you get from the
                    authenticator app and your password to confirm.
                  </Typography>
                  <IconButton
                    style={
                      auth?.userData?.twofactorauthentication
                        ? { background: "gray" }
                        : { background: "red" }
                    }
                  >
                    <Typography variant="h5">
                      {auth?.userData?.twofactorauthentication
                        ? "Enable"
                        : "Disable"}
                    </Typography>
                  </IconButton>
                </Box>

                <Box align="center" className="imgBox" mt={2}>
                  <img
                    // src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${"lskflksdjf@£$lksjdf9878957"}&choe=UTF-8`}
                    src={twoFaCodeData?.url}
                    alt=""
                  />
                </Box>

                {/* <Box align="center" mt={2}>
                    <QRCode
                      value={
                        twoFaCodeData?.url?.split(
                          "otpauth://totp/SecretKey?secret="
                        )[0]
                      }
                      fgColor="#000"
                      includeMargin
                      width={256}
                      height={256}
                      size={156}
                      renderAs="canvas"
                      imageSettings={{
                        src: "images/crashGameIcon.svg",
                        excavate: true,
                        scale: 2,
                        width: 55,
                        height: 55,
                      }}
                    />
                  </Box> */}

                <Box mt={2} className="endAdormentclx">
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="account"
                    placeholder="sdsggsgsgcsssxzxusw645rhdgsdsdsndns"
                    value={twoFaCodeData?.secret}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard text={twoFaCodeData?.secret}>
                            <IconButton
                              edge="start"
                              onClick={() => toast.success("Copied")}
                            >
                              <MdContentCopy />
                              &nbsp;
                              <Typography
                                variant="body2"
                                style={{
                                  color: "rgba(255, 255, 255, 0.60)",
                                }}
                              >
                                Copy
                              </Typography>
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box className="nodeBox" mt={2}>
                  <Typography variant="subtitle1">
                    <img src="images/explanationMark.svg" />
                    &nbsp; Important:&nbsp;
                    <span>
                      To prevent being locked out of your account, we recommend
                      writing the secret down on a piece of paper and keeping it
                      in a safe place.
                    </span>
                  </Typography>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    placeholder="Please enter password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">
                    Two Factor Authentication Code
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="twoFaCode"
                    placeholder="Please enter code"
                    value={values.twoFaCode}
                    error={Boolean(touched.twoFaCode && errors.twoFaCode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event) => handleNegativeValue(event)}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText error>
                    {touched.twoFaCode && errors.twoFaCode}
                  </FormHelperText>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                    disabled={isLoading}
                  >
                    {auth?.userData?.twofactorauthentication
                      ? "Disable"
                      : "Enable"}{" "}
                    Two-Factor Authentication {isLoading && <LoadingComp />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
