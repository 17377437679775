export const backTesttingbaseurl = "https://crash-backtesting.mobiloitte.io/"; //staging backtestingurl
// export const backTesttingbaseurl = "https://crash-bankroll.mobiloitte.io/"; //staging backtestingurl
export const baseurl = "https://node-crashgambling.mobiloitte.io"; ///staging server
// export const baseurl = "http://172.16.1.131:2004"; //staging server
// export const baseurl = "http://172.16.2.11:2004"; //local Nikhil server
// export const baseurl = "http://172.16.1.224:2004"; //local rangoli server
// export const baseurl = "http://172.16.1.217:2004"; //local new server
// export const baseurl = "http://172.16.1.131:2004"; //local mukul server
const ApiConfig = {
  register: `${baseurl}/register`,
  login: `${baseurl}/login`,
  resendOTP: `${baseurl}/resendOTP`,
  verifyOtp: `${baseurl}/verify-otp`,
  getUserProfile: `${baseurl}/getUserProfile`,
  forgotPassword: `${baseurl}/forgot-password`,
  verifyTokenForResetPassword: `${baseurl}/verify-token-for-reset-password`,
  updatePassword: `${baseurl}/updatePassword`,
  resetPassword: `${baseurl}/resetPassword`,
  updateEmail: `${baseurl}/updateEmail`,
  deleteAccount: `${baseurl}/deleteAccount`,
  twoFA: `${baseurl}/twoFA`,
  verifyTwoFA: `${baseurl}/verifyTwoFA`,
  contactUs: `${baseurl}/contactUs`,
  helpStaticContentList: `${baseurl}/helpStaticContentList`,
  retrieve: `${baseurl}/retrieve`,
  setAutoBet: `${baseurl}/setAutoBet`,
  verifyEmailOTP: `${baseurl}/verifyEmailOTP`,

  //chat api endpoints
  sendMessage: `${baseurl}/sendMessage`,
  getChat: `${baseurl}/getChat`,
  auto_cashout_early: `${baseurl}/auto_cashout_early`,
  manual_cashout_early: `${baseurl}/manual_cashout_early`,

  //bet api endpoints
  sendManualBet: `${baseurl}/sendManualBet`,
  myBets: `${baseurl}/myBets`,
  myAutoBets: `${baseurl}/myAutoBets`,
  // Despoiste
  deposit: `${baseurl}/deposit`,
  depositHistory: `${baseurl}/depositHistory`,

  // Widthdraw
  createWithdrawRequest: `${baseurl}/createWithdrawRequest`,
  withdrawHistory: `${baseurl}/withdrawHistory`,
  verifyWithdrawOtp: `${baseurl}/verify-withdraw-otp`,
  searchWithdrawRequest: `${baseurl}/searchWithdrawRequest`,
  searchWithdrawRequest: `${baseurl}/searchWithdrawRequest`,
  getCrashData: `${baseurl}/getCrashData`,

  //stats
  leaderBoard: `${baseurl}/leaderBoard`,
  withdrawTRX: `${baseurl}/withdrawTRX`,
  Tips: `${baseurl}/Tips`,
  tipsHistory: `${baseurl}/tipsHistory`,
  overview: `${baseurl}/overview`,

  //Landing Page
  topFiveUsers: `${baseurl}/api/v1/LandingPage/topFiveUsers`,

  //Crash game
  viewHistoryGame: `${baseurl}/viewHistoryGame`,
  listHistoryGame: `${baseurl}/listHistoryGame`,
  latestCrashValue: `${baseurl}/latestCrashValue`,
  totalWagerAmount: `${baseurl}/totalWagerAmount`,
  crashGameValue: `${baseurl}/crashGameValue`,
  gameHistory: `${baseurl}/gameHistory`,

  //Notifications
  getNotification: `${baseurl}/getNotification`,
  deleteNotification: `${baseurl}/api/v1/LandingPage/clearAllNotification`,

  //STATS
  stats: `${baseurl}/api/v1/LandingPage/stats`,

  //User Management
  userList: `${baseurl}/userList`,
  userWalletList: `${baseurl}/userWalletList`,
  blockUser: `${baseurl}/api/v1/LandingPage/blockUser`,
  deleteUser: `${baseurl}/api/v1/LandingPage/deleteUser`,
  bettingListUser: `${baseurl}/api/v1/LandingPage/bettingListUser`,
  viewUserProfile: `${baseurl}/api/v1/LandingPage/viewUserProfile`,
  feeUpdate: `${baseurl}/api/v1/LandingPage/feeUpdate`,
  feeList: `${baseurl}/api/v1/LandingPage/feeList`,
  twoFaEnableDisable: `${baseurl}/api/v1/LandingPage/twoFaEnableDisable`,

  //Static Content Management
  listStaticContent: `${baseurl}/api/v1/LandingPage/listStaticContent`,
  viewStaticContent: `${baseurl}/api/v1/LandingPage/viewStaticContent`,
  editStaticContent: `${baseurl}/api/v1/LandingPage/editStaticContent`,
  viewStaticContenwWithType: `${baseurl}/api/v1/LandingPage/viewStaticContenwWithType`,
  userWalletListForAdmin: `${baseurl}/api/v1/LandingPage/userWalletListForAdmin`,

  //Faq Management
  listFAQ: `${baseurl}/api/v1/LandingPage/listFAQ`,
  viewFAQ: `${baseurl}/api/v1/LandingPage/viewFAQ`,
  editFAQ: `${baseurl}/api/v1/LandingPage/editFAQ`,
  deleteFaq: `${baseurl}/api/v1/LandingPage/deleteFaq`,
  addFAQ: `${baseurl}/api/v1/LandingPage/addFAQ`,

  //Dashboard
  totalUsers: `${baseurl}/api/v1/LandingPage/totalUsers`,
};
export default ApiConfig;
