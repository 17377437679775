import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { GiBackwardTime } from "react-icons/gi";
import WithdrawalSendModal from "./WithdrawalSendModal";
import { apiRouterCall } from "../ApiConfig/service";
import ApiConfig from "../ApiConfig/ApiConfig";
import { sortAddress } from "../utils";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .MuiDialogActions-root": {
      padding: "8px 0px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
  },
}));

const tableHead = [
  {
    heading: "Date & Time",
  },
  {
    heading: "Amount",
  },
  {
    heading: "Fee",
  },
  {
    heading: "STATUS",
  },
  // {
  //   heading: "ADDRESS",
  // },
];
export default function WithdrawalHistoryModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [sendWithdrawal, setSendWithdrawal] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNumPages] = useState(1);
  const [numTotalPages, setNumTotalPages] = useState(1);
  const [widthdrawHistory, setWidthdrawHistory] = useState([]);
  console.log("noOfPages", noOfPages, numTotalPages);
  const widthDrawHistory = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.withdrawHistory,
        paramsData: {
          page: page,
          limit: 10,
        },
      });
      console.log("ydgyfgygfd", res);
      if (res.data?.statusCode == 200) {
        setWidthdrawHistory(res.data.responseresult?.docs);
        setNumPages(res.data?.responseresult?.pages);
        setNumTotalPages(res?.data?.responseresult?.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (confirmModal) {
      widthDrawHistory();
    }
  }, [confirmModal, page]);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <GiBackwardTime color="#fff" />
          &nbsp;&nbsp;
          <Typography variant="h5">WITHDRAWAL HISTORY</Typography>
        </Box>

        <DialogContent>
          <Box className="mainBox" mt={1.4}>
            <TableComp
              tableHead={tableHead}
              scoreListData={
                widthdrawHistory &&
                widthdrawHistory.map((item, i) => ({
                  ["Date & Time"]: moment(item?.createdAt).format("lll"),
                  ["Amount"]: `${item?.WithdrawAmount} TRX`,
                  ["Fee"]: `${item?.WithdrawFee} TRX`,
                  STATUS: item?.transactionStatus,
                  // ["DEPOSIT ADDRESS"]:
                  //   item?.transactionId && sortAddress(item?.transactionId),
                }))
              }
              noOfPages={noOfPages}
              numTotalPages={numTotalPages}
              page={page}
              setPage={setPage}
              limit={10}
              isLoading={isLoading}
            />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Box align="end">
            <Button
              variant="outlined"
              color="secondary"
              disabled={isLoading}
              onClick={() => setSendWithdrawal(true)}
            >
              REQUEST A WITHDRAWAL
            </Button>
          </Box>
        </DialogActions> */}
      </Dialog>
      <WithdrawalSendModal
        handleClose={() => setSendWithdrawal(false)}
        confirmModal={sendWithdrawal}
      />
    </>
  );
}
