import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { postAPIHandler } from "../ApiConfig/service";
import { toast } from "react-hot-toast";
import Timer from "./Timer";
import LoadingComp from "./LoadingComp";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
  },
}));

export default function PassCodeModal({
  handleClose,
  confirmModal,
  setModalOpen,
  data,
  auth,
  type,
  otpType,
  setLoginModalOpen,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    passcode: "",
  };

  const validationSchema = yup.object().shape({
    passcode: yup
      .string()
      .required("Please enter otp.")
      .matches(/^[0-9]{6}$/, "otp must be exactly 6 digits."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: type === "OTP" ? "verifyEmailOTP" : "verifyOtp",
        dataToSend:
          otpType === "Login"
            ? {
                email: data,
                otp: values.passcode,
              }
            : type === "OTP"
            ? {
                otp: values.passcode,
                newEmail: data.email,
              }
            : {
                otp: values.passcode,
                email: data.email,
              },
      });
      console.log("response----", response);
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        auth.userLogIn(true, response.data.responseResult);
        window.sessionStorage.setItem("token", response.data.responseResult);
        auth.getUserProfile(response.data.responseResult);
        setIsLoading(false);
        handleClose();
        setLoginModalOpen(false);
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // toast.error(error?.response?.data?.responseMessage);
    }
  };
  const handleReSubmitDataFun = async () => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "resendOTP",
        dataToSend: {
          email: data,
        },
      });
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        // auth.userLogIn(true, response.data.responseResult);
        // window.sessionStorage.setItem("token", response.data.responseResult);
        // auth.getUserProfile(response.data.responseResult);
        setIsLoading(false);
        // handleClose();
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // toast.error(error?.response?.data?.responseMessage);
    }
  };
  // useEffect(() => {
  //   if (otpType === "Login") {
  //     handleReSubmitDataFun();
  //   }
  // }, [otpType]);

  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="xs"
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitDataFun(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Box align="center" mb={2} p={3}>
                <Typography variant="h5">
                  {type === "OTP" ? "VERIFY PASSCODE" : "LOGIN"}
                </Typography>
                <Box my={2}>
                  <Typography variant="body1">
                    Please enter the 6 digit verification code that was sent to
                    &nbsp;{data.email}.
                  </Typography>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">OTP</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="passcode"
                    placeholder="Please enter otp."
                    value={values.passcode}
                    error={Boolean(touched.passcode && errors.passcode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passLock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onKeyDown={(event) => handleNegativeValue(event)}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/" ||
                        event?.key === "."
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText error>
                    {touched.passcode && errors.passcode}
                  </FormHelperText>
                </Box>
                <Box className="displaySpacebetween">
                  <Box style={{ width: "100%" }}>
                    <Timer emailData={data.email} />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  {type === "OTP" ? "SUBMIT" : "LOGIN"}{" "}
                  {isLoading && <LoadingComp />}
                </Button>
                {type !== "OTP" && (
                  <>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => !isLoading && handleClose()}
                        // onClick={() => handleReSubmitDataFun()}
                      >
                        To request a new code, please login again
                      </Typography>
                    </Box>
                    <Box mt={2} className={classes.mainBox}>
                      <Typography variant="subtitle1">
                        Don't have an account?{" "}
                        <span
                          onClick={() => {
                            handleClose();
                            setModalOpen(true);
                          }}
                        >
                          Register an account
                        </span>
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
