import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { RiLockPasswordFill } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },

    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& img": {
      width: "80px",
      height: "80px",
    },
    "& .MuiTypography-subtitle1": {
      textAlign: "left",
      color: "rgba(255, 255, 255, 0.87)",
      "& span": {
        fontWeight: 600,
      },
    },
  },
}));

export default function ChangeRollConfirmation({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const initialValues = {
    password: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
  });
  const handleSubmitDataFun = async (values) => {};

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <DialogContent>
          <Box align="center" mb={2}>
            <img src="images/deleteAccountLogout.png" />
            <Box mb={2}>
              <Typography variant="subtitle1">
                Double-check the form for errors and make sure you understand
                the consequences before adjusting your investment!
                <br />
                Afterwards, your investment will have changed by approximately{" "}
                <span>0 bet</span>
              </Typography>
            </Box>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmitDataFun(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Box mt={2} align="start">
                    <Typography variant="body1">Confirm Password</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword.password ? "text" : "password"}
                      name="password"
                      placeholder="Please enter password"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start">
                              <RiLockPasswordFill />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                            >
                              {showPassword.password ? (
                                <HiEye className={classes.iconsclass1} />
                              ) : (
                                <HiEyeOff className={classes.iconsclass1} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box className="displaySpacebetween" mt={0.3}>
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>
                      <Typography
                        variant="body1"
                        style={{ cursor: "pointer" }}
                        onClick={() => setForgotModalOpen(true)}
                      >
                        Forgot Password?
                      </Typography>
                    </Box>
                  </Box>

                  <Box align="center" mt={2.25}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClose}
                      disabled={isLoading}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      style={{ marginLeft: "10px" }}
                      disabled={isLoading}
                    >
                      SUBMIT {isLoading && <LoadingComp />}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
      <ForgotPasswordModal
        setForgotModalOpen={setForgotModalOpen}
        confirmModal={forgotModalOpen}
        setLoginModalOpen={setForgotModalOpen}
      />
    </>
  );
}
