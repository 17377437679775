import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  makeStyles,
  FormHelperText,
  Grid,
  DialogActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue } from "../utils";
import { HiOutlineCodeBracket } from "react-icons/hi2";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthMd": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .imgBox": {
      "& img": {
        width: "144px",
      },
    },
    "& .nodeBox": {
      border: "2px solid #DF1104",
      background: "#332528",
      padding: "12px",
      borderRadius: "5px",
      width: "100%",
      "& button": {
        background: "#DF1104",
        "&:hover": {
          border: "none",
          boxShadow: "none",
        },
      },
    },
    "& .amountBoxClx": {
      display: "flex",
      width: "100%",
      gap: "8px",
      "& .iconButton": {
        background: "#2F3034",
        borderRadius: "5px",
        height: "52px",
      },
    },
    "& .balanceAvaiclx": {
      background: "#1D1F23",
      padding: "12px 16px",
      borderRadius: "5px",
      fontSize: "12px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "12px",
      },
    },
    "& .MuiDialogContent-root": {
      overflowX: "hidden",
      padding: "8px 0px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "12px",
      },
    },
    "& .MuiTypography-subtitle1": {
      color: "rgba(255, 255, 255, 0.87)",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "start",
      padding: "8px 0px",
    },
  },
}));
export default function BackTestingModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptRisk, setAcceptRisk] = useState(false);

  const initialValues = {
    noOfGames: "",
    gameHash: "",
    initialBalance: "",
    initialBankroll: "",
  };

  const validationSchema = yup.object().shape({
    noOfGames: yup
      .string()
      .trim()
      .required("Please enter number of game.")
      .max(8, "You can enter only 8 characters."),
    initialBalance: yup
      .string()
      .trim()
      .required("Please enter initial balance.")
      .max(8, "You can enter only 8 characters."),
    initialBankroll: yup
      .string()
      .trim()
      .required("Please enter initial bankroll.")
      .max(8, "You can enter only 8 characters."),
    gameHash: yup.string().trim().required("Please enter hash."),
  });

  const handleSubmitDataFun = async (values) => {};
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => {
          !isLoading && handleClose();
          setAcceptRisk(false);
        }}
        fullWidth
        maxWidth="md"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => {
            setAcceptRisk(false);
            handleClose();
          }}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <HiOutlineCodeBracket />
          &nbsp;&nbsp;
          <Typography variant="h5">BACKTESTING</Typography>
        </Box>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Backtesting can help gauge the effectiveness of a strategy
                  without risking real money
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  First choose how to generate games (either historical or based
                  on an arbitrary/random hash) and select the script you want to
                  test. Click "Run Script" and wait for it to complete or click
                  "Stop Script" at any time to stop the simulation. When the
                  simulation stops, CSV reports will be available for download
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Note that using the last game's hash currently generates game
                  results backwards. If you are interested in the technical
                  details of bustabit's provably fair system, have a look at
                  this <span>thread</span> on the Tron forums.
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Be aware that while backtesting helps interpreting how a
                  strategy behaves, it can't predict future performance.
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Be aware that while backtesting helps interpreting how a
                  strategy behaves, it can't predict future performance.
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  First choose how to generate games (either historical or based
                  on an arbitrary/random hash) and select the script you want to
                  test. Click "Run Script" and wait for it to complete or click
                  "Stop Script" at any time to stop the simulation. When the
                  simulation stops, CSV reports will be available for download
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Be aware that while backtesting helps interpreting how a
                  strategy behaves, it can't predict future performance.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box mt={1}>
                <Typography variant="h5">Configuration</Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  How many games do you want to simulate?
                </Typography>
              </Box>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmitDataFun(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form>
                    <Box mt={2} className="withoutAdclx">
                      <Typography variant="body1">Number of games</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="noOfGames"
                        value={values.noOfGames}
                        error={Boolean(touched.noOfGames && errors.noOfGames)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.noOfGames && errors.noOfGames}
                      </FormHelperText>
                    </Box>

                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        How many games do you want to simulate?
                      </Typography>
                    </Box>
                    <Box mt={2} className="withoutAdclx">
                      <Typography variant="body1">Hash</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="gameHash"
                        value={values.gameHash}
                        error={Boolean(touched.gameHash && errors.gameHash)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <FormHelperText error>
                        {touched.gameHash && errors.gameHash}
                      </FormHelperText>
                      <Box className="displaySpacebetween" mt={0.5}>
                        <span>What is this?</span>
                        <span>Generate random hash</span>
                        <span>Use last game's hash</span>
                      </Box>
                    </Box>

                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        Fields provided to the Script Editor API
                      </Typography>
                    </Box>
                    <Box mt={2} className="withoutAdclx">
                      <Typography variant="body1">Initial balance</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="initialBalance"
                        value={values.initialBalance}
                        error={Boolean(
                          touched.initialBalance && errors.initialBalance
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.initialBalance && errors.initialBalance}
                      </FormHelperText>
                    </Box>
                    <Box mt={2} className="withoutAdclx">
                      <Typography variant="body1">Initial bankroll</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="initialBankroll"
                        value={values.initialBankroll}
                        error={Boolean(
                          touched.initialBankroll && errors.initialBankroll
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.initialBankroll && errors.initialBankroll}
                      </FormHelperText>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
        {!acceptRisk && (
          <DialogActions>
            <Box className="nodeBox">
              <Typography variant="subtitle1">
                <img src="images/explanationMark.svg" />
                &nbsp;&nbsp; Bustatrx is not responsible for script bugs or
                scripts that lose your money. Use them at your own risk or don't
                use them at all.
              </Typography>
              <Box align="end" mt={0.5}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => setAcceptRisk(true)}
                >
                  I Accept the risk {isLoading && <LoadingComp />}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
