import React, { useState } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import Terms from "./Terms";
import BackTestingModal from "../../component/BackTestingModal";
import LeaderBoardModal from "../../component/LeaderBoardModal";
import { backTesttingbaseurl } from "../../ApiConfig/ApiConfig";
import Privacy from "./Privacy";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "50px 0px",
    zIndex: "2",
    overflow: " hidden",
    background: "#26272B",
    "& .footericonButton": {
      background: "#EB7D2D",
      padding: "10px !important",
      "& svg": {
        color: "#fff",
        fontSize: "22px",
        // fontSize: "15px",
      },
    },
    "& .footerborder": {
      color: "#fff",
      fontSize: "15px",
    },

    "& .footerbordertext": {
      color: "#fff",
      display: "flex",
      fontSize: "15px",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "300",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      padding: "0px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        justifyContent: "start",
        padding: "5px 0",
      },
      // "& :hover": {
      //   color: "#FF6600",
      //   textDecoration: "none",
      // },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogPrivacy, setOpenDialogprivacy] = useState(false);
  const [openModal, setOpenModal] = useState({
    backtesting: false,
    leaderboard: false,
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialogPrivacy = () => {
    setOpenDialogprivacy(true);
  };

  const handleCloseDialogPrivacy = () => {
    setOpenDialogprivacy(false);
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container>
          <Box align="center" mt={2} mb={2}>
            <ScrollAnimation animateIn="flash">
              <img src="images/logo.png" alt="logo" />
            </ScrollAnimation>
            <Box className="displayCenter" mt={3}>
              <List>
                <ListItem
                  // to="/term-and-condition"
                  // component={RouterLink}
                  className=" footerbordertext"
                  onClick={() => window.open(`${backTesttingbaseurl}`)}
                >
                  Backtesting
                </ListItem>
              </List>{" "}
              &nbsp; &nbsp;
              <List>
                <ListItem
                  // to="/privacy-policy"
                  // component={RouterLink}
                  onClick={() =>
                    setOpenModal({ ...openModal, leaderboard: true })
                  }
                  className="footerborder footerbordertext"
                >
                  Leaderboard
                </ListItem>
              </List>
              &nbsp; &nbsp;
              <List>
                <ListItem
                  className="footerborder footerbordertext"
                  onClick={handleOpenDialog}
                  style={{ cursor: "pointer" }}
                >
                  Terms of Services
                </ListItem>
              </List>
              &nbsp; &nbsp;
              <List>
                <ListItem
                  className="footerborder footerbordertext"
                  onClick={handleOpenDialogPrivacy}
                  style={{ cursor: "pointer" }}
                >
                  Privacy Policy
                </ListItem>
              </List>
            </Box>
            <Box align="center" mt={2} mb={2}>
              <Box className="displayCenter">
                <IconButton
                  className="footericonButton"
                  href="https://twitter.com/"
                  target="_blank"
                >
                  <AiOutlineTwitter />
                </IconButton>{" "}
                &nbsp; &nbsp;
                <IconButton
                  className="footericonButton"
                  href="https://telegram.org/"
                  target="_blank"
                >
                  <FaTelegramPlane />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Terms open={openDialog} handleClose={handleCloseDialog} />
          <Privacy open={openDialogPrivacy} handleClose={handleCloseDialogPrivacy} />
        </Container>
      </Box>
      <BackTestingModal
        handleClose={() => setOpenModal({ ...openModal, backtesting: false })}
        confirmModal={openModal.backtesting}
      />
      <LeaderBoardModal
        handleClose={() => setOpenModal({ ...openModal, leaderboard: false })}
        confirmModal={openModal.leaderboard}
      />
    </>
  );
}
