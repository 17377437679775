import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { putAPIHandler } from "../ApiConfig/service";
import { toast } from "react-hot-toast";
import { AuthContext } from "../context/Auth";
import PassCodeModal from "./PassCodeModal";
import CreateAccountModal from "./CreateAccountModal";
import LoginModal from "./LoginModal";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
}));
export default function UpdateEmailModal({
  handleClose,
  confirmModal,
  closeSecurityModal,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [passCodeModalOpen, setPassCodeModalOpen] = useState(false);
  console.log("sgergr", passCodeModalOpen);
  const [modalOpen, setModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required.")
      .test(
        "unique-address",
        "Please enter a different email address.",
        function (value) {
          return value !== auth.userData.email;
        }
      ),
    password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      )
      .required("Please enter password.")
      .min(6, "Please enter atleast 6 characters.")
      .max(18, "You can enter only 18 characters."),
  });
  const handleUpdateEmail = async (values) => {
    try {
      setIsLoading(true);
      const response = await putAPIHandler({
        endPoint: "updateEmail",
        dataToSend: {
          newEmail: values.email,
          password: values.password,
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setPassCodeModalOpen(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <img src="images/emailIcon.svg" />
          &nbsp;&nbsp;
          <Typography variant="h5">UPDATE EMAIL</Typography>
        </Box>

        <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
          Your email address is used to secure your account and reset your
          password in case you forget it.
          <br />
          Current email address: {auth.userData.email}
          <br />
          If you want to use a different email address you can change it below:
        </Typography>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleUpdateEmail(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={1} align="start">
                  <Typography variant="body1">Email</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    placeholder="Please enter email address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="images/emailImg.svg" alt="email" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.email && errors.email}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    placeholder="Please enter password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                    disabled={isLoading}
                  >
                    CHANGE EMAIL ADDRESS {isLoading && <LoadingComp />}
                  </Button>
                </Box>
                {passCodeModalOpen && (
                  <PassCodeModal
                    handleClose={() => {
                      setPassCodeModalOpen(false);
                      closeSecurityModal();
                      handleClose();
                    }}
                    setLoginModalOpen={setLoginModalOpen}
                    confirmModal={passCodeModalOpen}
                    setModalOpen={setModalOpen}
                    data={values}
                    auth={auth}
                    type="OTP"
                  />
                )}

                <CreateAccountModal
                  confirmModal={modalOpen}
                  setLoginModalOpen={setLoginModalOpen}
                  setModalOpen={setModalOpen}
                />
                <LoginModal
                  setLoginModalOpen={setLoginModalOpen}
                  confirmModal={loginModalOpen}
                  setModalOpen={setModalOpen}
                />
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
