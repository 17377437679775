import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { FiSearch } from "react-icons/fi";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactUsModal from "./ContactUsModal";
import { getAPIHandler } from "../ApiConfig/service";
import NoDataFound from "./NoDataFound";
import moment from "moment";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
      minHeight: "400px",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0px 0px",
      marginTop: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .MuiDialogActions-root": {
      padding: "10px 0px 0px",
    },
  },
  root: {
    width: "100%",
    "& svg": {
      color: "#fff !important",
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 0px 16px",
      borderTop: "1px solid #484949",
      margin: "0px 14px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px",
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "6px 0",
      },
    },
    "& .MuiAccordion-rounded": {
      marginBottom: "6px",
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      color: "#fff",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px 0px 6px",
    },
  },
}));

export default function HelpSupportModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [contactMol, setContactMol] = useState(false);
  const [helpSupportData, setHelpSupportData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [isFaqUpdating, setIsFaqDataUpdating] = useState(false);
  const [page, setPage] = useState(1);
  const [filtersData, setFiltersData] = useState({
    fromDate: null,
    toDate: null,
    search: "",
    status: "",
  });
  console.log("filtersDatafiltersData", filtersData);
  const faqManagementApi = async (source) => {
    try {
      setFaqData([]);
      setIsFaqDataUpdating(true);
      const response = await getAPIHandler({
        endPoint: "listFAQ",
        paramsData: {
          filter: filtersData?.search ? filtersData?.search : null,
          page: page,
          limit: 10,
          fromDate: filtersData.fromDate
            ? moment(filtersData.fromDate).format("YYYY-MM-DD")
            : null,
          toDate: filtersData.toDate
            ? moment(filtersData.toDate).format("YYYY-MM-DD")
            : null,
          status1: filtersData?.status ? filtersData?.status : null,
        },
        source: source,
      });
      if (response.data.statusCode == 200) {
        setFaqData(response.data.responseResult.docs);
        setIsFaqDataUpdating(false);
      }
      setIsFaqDataUpdating(false);
    } catch (error) {
      setIsFaqDataUpdating(false);
    }
  };

  useEffect(() => {
    faqManagementApi();
  }, [filtersData?.search]);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="sm"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <AiFillQuestionCircle />
          &nbsp;&nbsp;
          <Typography variant="h5">HELP</Typography>
        </Box>
        <Box mt={2} className="searchFieldBox">
          <TextField
            variant="outlined"
            fullWidth
            placeholder="How can we help?"
            type="search"
            value={filtersData?.search}
            onChange={(e) =>
              setFiltersData({ ...filtersData, ["search"]: e.target.value })
            }
            InputProps={{
              startAdornment: (
                <FiSearch position="start" style={{ fontSize: "18px" }} />
              ),
            }}
          />
        </Box>

        <DialogContent>
          <Box className={classes.root}>
            {faqData &&
              faqData?.map((item, i) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="body1">
                        {item && item.question ? item.question : "--"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item && item.answer ? item.answer : "--",
                          }}
                        ></div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            {!isFaqUpdating && faqData && faqData?.length === 0 && (
              <NoDataFound text={"No user data found!"} />
            )}
            {!isFaqUpdating &&
              faqData &&
              faqData.length >= (page === 1 ? 10 : 0) && (
                <Box my={2} display="flex" justifyContent="flex-end">
                  <Pagination
                    page={page}
                    count={numPages}
                    onChange={(e, v) => {
                      setPage(v);
                    }}
                    variant="outlined"
                    shape="rounded"
                  />
                </Box>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box align="end">
            <Button
              variant="outlined"
              color="secondary"
              disabled={isLoading}
              onClick={() => setContactMol(true)}
            >
              Contact Us
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ContactUsModal
        handleClose={() => setContactMol(false)}
        confirmModal={contactMol}
      />
    </>
  );
}
