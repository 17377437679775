import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { TfiEmail } from "react-icons/tfi";
import { AiOutlineSetting } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBoxClx": {
      border: "1px solid #2c2d30",
      padding: "14px 12px",
      borderRadius: "5px",
      background: "#28292d",
      "& .headingFieldSta": {
        borderBottom: "1px solid #484949",
        paddingBottom: "8px",
        marginBottom: "13px",
        "& p": {
          fontSize: "16px",
          color: "#fff",
        },
        "& button": {
          borderRadius: "45px",
          padding: "0px",
          fontSize: "10px",
        },
      },
    },
    "& .scrollClx": {
      overflow: "auto",
    },
  },
}));
export default function SettingModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [passButtonFlag, setButtonFlag] = useState("");

  const initialValues = {
    email: "",
    phone: "",
  };

  const validationSchema = yup.object().shape({
    email:
      passButtonFlag === "0" &&
      yup
        .string()
        .max(100, "Should not exceeds 100 characters.")
        .email("Please enter a valid email address.")
        .required("Email is required."),
    phone:
      passButtonFlag === "1" &&
      yup
        .string()
        .required("Phone number is required.")
        .max(13, "Enter a valid phone number.")
        .min(7, "Must be only 7 digits.")
        .test(
          "notAllRepeatedDigits",
          "Phone number cannot have all repeated digits.",
          (value) => {
            const numericValue = value?.replace(/[^0-9]/g, "");
            return !/(\d)\1{6,}/.test(numericValue);
          }
        ),
  });

  const handleSubmitDataFun = async (values) => {};
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <AiOutlineSetting />
          &nbsp;&nbsp;
          <Typography variant="h5">SETTING</Typography>
        </Box>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitDataFun(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={2} className="mainBoxClx">
                  <Box align="start">
                    <Box className="headingFieldSta displayStart">
                      <Typography variant="body2">Email</Typography>
                      &nbsp;&nbsp;&nbsp;
                      <Button variant="contained" color="primary">
                        Verified
                      </Button>
                    </Box>
                    <Typography variant="body1">Email</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="email"
                      name="email"
                      placeholder="Please enter email address"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start">
                              <TfiEmail />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box className="displaySpacebetween" mt={0.3}>
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                      <Typography variant="body1" style={{ cursor: "pointer" }}>
                        Resend Email
                      </Typography>
                    </Box>
                  </Box>

                  <Box align="center" mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => setButtonFlag("0")}
                    >
                      SAVE {isLoading && <LoadingComp />}
                    </Button>
                  </Box>
                </Box>
                <Box mt={2} className="mainBoxClx scrollClx">
                  <Box align="start">
                    <Box className="headingFieldSta">
                      <Typography variant="body2">Phone Number</Typography>
                      <Typography variant="subtitle1">
                        We only service areas that are listed in the available
                        country code list.
                      </Typography>
                    </Box>
                    <Typography variant="body1">Phone Number</Typography>
                    <Box className="phoneInputClx">
                      <PhoneInput
                        country={"in"}
                        name="phone"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={values.phone}
                        error={Boolean(touched.phone && errors.phone)}
                        onBlur={handleBlur}
                        onChange={(phone, e) => {
                          setCountryCode(e.dialCode);
                          setFieldValue("phone", phone);
                        }}
                      />
                    </Box>

                    <FormHelperText error>
                      {touched.phone && errors.phone}
                    </FormHelperText>
                  </Box>

                  <Box align="center" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => setButtonFlag("1")}
                    >
                      SAVE {isLoading && <LoadingComp />}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
