import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { GiBackwardTime } from "react-icons/gi";
import { apiRouterCall } from "../ApiConfig/service";
import ApiConfig from "../ApiConfig/ApiConfig";
import LoadingComp from "./LoadingComp";
import { sortAddress } from "../utils";
import { toast } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import moment from "moment";
import LinkIcon from "@material-ui/icons/Link";
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .MuiDialogActions-root": {
      padding: "8px 0px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
    "& .displayStart1": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
}));

const tableHead = [
  {
    heading: "DATE & TIME",
  },
  {
    heading: "AMOUNT",
  },
  {
    heading: "STATUS",
  },
  {
    heading: "HASH ADDRESS",
  },
];
export default function DepositHistoryModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [contactEmail, setContactEmail] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNumPages] = useState(1);
  const [numTotalPages, setNumTotalPages] = useState(1);
  const [despositeHistory, setDepositeHistory] = useState([]);
  console.log("noOfPagesnoOfPages", noOfPages, numTotalPages);
  const depositeHistory = async () => {
    try {
      const resData = await apiRouterCall({
        method: "POST",
        url: ApiConfig.deposit,
      });
      console.log(">>>>>resData123", resData);
      if (resData?.data?.statusCode === 500) {
        toast.error(resData?.data?.responseMessage);
        setContactEmail(true);
      }
      console.log(">>>>>resData", resData);
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.depositHistory,
        paramsData: {
          page: page,
          limit: 10,
        },
      });

      if (res.data.statusCode === 200) {
        setDepositeHistory(res.data?.responseResult?.docs);
        setNumPages(res.data?.responseResult?.pages);
        setNumTotalPages(res?.data?.responseResult?.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error>>>>>error", error.response);
      console.log("error>>>>>error1", error);
    }
  };
  useEffect(() => {
    if (confirmModal) {
      depositeHistory();
    }
  }, [confirmModal]);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="sm"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <GiBackwardTime />
          &nbsp;&nbsp;
          <Typography variant="h5">DEPOSIT HISTORY</Typography>
        </Box>

        <DialogContent>
          <Box className="mainBox" mt={1.4}>
            {isLoading ? (
              <LoadingComp />
            ) : (
              <TableComp
                tableHead={tableHead}
                scoreListData={
                  despositeHistory &&
                  despositeHistory.map((item, i) => ({
                    "DATE & TIME": moment(item?.createdAt).format("lll"),
                    AMOUNT: `${item?.depositAmount} TRX`,
                    STATUS: item?.transactionStatus,
                    ["HASH ADDRESS"]: (
                      <Box className="displayStart1">
                        <Link>
                          <Typography
                            variant="body1"
                            className="descriptionText"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              window.open(
                                `https://nile.tronscan.org/#/transaction/${item?.transactionId}`
                              )
                            }
                          >
                            {item?.transactionId &&
                              sortAddress(item?.transactionId)}
                          </Typography>
                        </Link>
                        &nbsp;
                        <CopyToClipboard
                          text={item?.transactionId && item?.transactionId}
                        >
                          <IconButton
                            onClick={() => toast.success("Copied")}
                            style={{
                              fontSize: "12px",
                              marginRight: "0px",
                            }}
                          >
                            <MdContentCopy />
                          </IconButton>
                        </CopyToClipboard>
                        <IconButton
                          onClick={() =>
                            window.open(
                              `https://nile.tronscan.org/#/transaction/${item?.transactionId}`
                            )
                          }
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <LinkIcon style={{ height: "20px" }} />
                        </IconButton>
                      </Box>
                    ),
                  }))
                }
                noOfPages={noOfPages}
                numTotalPages={numTotalPages}
                page={page}
                setPage={setPage}
                limit={10}
                isLoading={isLoading}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {contactEmail && (
        <Dialog
          maxWidth="sm"
          className={classes.mainRoot}
          fullWidth
          open={contactEmail}
          onClose={() => setContactEmail(false)}
        >
          <IconButton
            style={{ position: "absolute", right: 0, top: 0 }}
            disabled={isLoading}
            onClick={() => setContactEmail(false)}
          >
            <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
          </IconButton>
          <DialogContent>
            <Box>
              <Typography variant="h5">
                You don't have enough and bandwidth remains in your wallet to
                perform for deposit.
              </Typography>
              <Typography>Please contact to support@bustarx.com</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
