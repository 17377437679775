import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  mainLoading: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black overlay
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& h4": {
      color: "#fff",
    },
  },
}));

export default function LoadingComp() {
  const classes = useStyles();
  return (
    <Box className={classes.mainLoading}>
      <Box>
        <img src="images/logo.png" alt="loading" />
        <Typography variant="h4">Loading...</Typography>
      </Box>
    </Box>
  );
}
