import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue } from "../utils";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
}));
export default function WithdrawalSendModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    passcode: "",
  };

  const validationSchema = yup.object().shape({
    passcode: yup
      .string()
      .required("Please enter passcode.")
      .matches(/^[0-9]{6}$/, "Passcode must be exactly 6 digits."),
  });

  const handleSubmitDataFun = async (values) => {};
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <img src="images/withdrawalIcon.svg" />
          &nbsp;&nbsp;
          <Typography variant="h5">WITHDRAWALS</Typography>
        </Box>

        <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
          Please enter the one time passcode that you will get on your
          registered email address.
        </Typography>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitDataFun(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={2} align="start">
                  <Typography variant="body1">Passcode</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="passcode"
                    placeholder="Please enter code"
                    value={values.passcode}
                    error={Boolean(touched.passcode && errors.passcode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <RiLockPasswordFill />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(event) => handleNegativeValue(event)}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText error>
                    {touched.passcode && errors.passcode}
                  </FormHelperText>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    SUBMIT
                    {isLoading && <LoadingComp />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
