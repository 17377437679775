import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { handleNegativeValue } from "../utils";
import { toast } from "react-hot-toast";
import { GiBackwardTime } from "react-icons/gi";
import WithdrawalHistoryModal from "./WithdrawalHistoryModal";
import { apiRouterCall } from "../ApiConfig/service";
import ApiConfig from "../ApiConfig/ApiConfig";
import { AuthContext } from "../context/Auth";
import VerifyModal from "./widthdrawOtpVerify/VerifyModal";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      paddingBottom: "12px",
      "& svg": {
        fontSize: "17px",
      },
    },
    "& .displaySpacebetween": {
      borderBottom: "1px solid #484949",
      "& .MuiIconButton-root": {
        padding: "0px",
        marginTop: "-10px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .imgBox": {
      "& img": {
        width: "144px",
      },
    },
    "& .nodeBox": {
      border: "2px solid #DF1104",
      background: "#332528",
      padding: "12px",
      borderRadius: "5px",
      "& subtitle1": {
        fontWeight: 500,
      },
    },
    "& .classHistory": {
      "& .MuiIconButton-root": {
        padding: "0px 12px",
        color: "rgba(255, 255, 255, 0.60)",
      },
      "& .MuiButtonBase-root": {
        alignItems: "start",
      },
    },
    "& .mainForm": {
      [theme.breakpoints.down("sm")]: {
        marginRight: "8px",
      },
    },
    "& .amountBoxClx": {
      display: "flex",
      width: "100%",
      gap: "8px",
      "& .iconButton": {
        background: "#2F3034",
        borderRadius: "5px",
        height: "52px",
      },
    },
    "& .balanceAvaiclx": {
      background: "#1D1F23",
      padding: "12px 16px",
      borderRadius: "5px",
      fontSize: "12px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "12px",
      },
    },
  },
}));
export default function WithdrawalModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawHis, setWithdrawHis] = useState(false);
  const [maxValue, setMaxValue] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  let initialValues = {
    tronAddress: "",
    amount: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    tronAddress: yup
      .string()
      .trim()
      .required("Please enter address.")
      .matches(
        /^(T|A)[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{33}$/,
        "Please enter a valid address."
      )
      .test(
        "unique-address",
        "Please enter a different wallet address.",
        function (value) {
          return value !== userData.address;
        }
      ),
    amount: yup
      .string()
      .trim()
      .required("Please enter amount.")
      .max(8, "You can enter only 8 characters."),
    password: yup
      .string()
      .trim()
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
      //   "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      // )
      .required("Please enter password."),
    // .min(6, "Please enter atleast 6 characters.")
    // .max(18, "You can enter only 18 characters."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.createWithdrawRequest,
        bodyData: {
          recieverAddress: values.tronAddress,
          password: values.password,
          amount: values.amount,
        },
      });
      if (res.data.statusCode === 200) {
        setIsLoading(false);
        toast.success(res.data.responseMessage);
        setOpen(true);
      } else {
        setIsLoading(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log("error=-=-=", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {open && (
        <VerifyModal
          handleClose={handleClose}
          open={open}
          setOpen={(item) => setOpen(item)}
        />
      )}
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displaySpacebetween" mt={2}>
          <Box className="displayStart">
            <img src="images/withdrawalIcon.svg" />
            &nbsp;&nbsp;
            <Typography variant="h5">WITHDRAW</Typography>
          </Box>
          <IconButton onClick={() => setWithdrawHis(true)}>
            <GiBackwardTime color="#EB7D2D" />
          </IconButton>
        </Box>

        <Box display="flex" mt={2} className="classHistory">
          <Typography variant="subtitle1">
            Part of your balance may not be withdrawable if you have unconfirmed
            precredited deposits or are participatingin the current game.
          </Typography>
        </Box>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmitDataFun(values);
              resetForm();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form className="mainForm">
                <Box mt={2} className="withoutAdclx">
                  <Typography variant="body1">TRON Address</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="tronAddress"
                    value={values.tronAddress}
                    error={Boolean(touched.tronAddress && errors.tronAddress)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter your TRON address"
                  />
                  <FormHelperText error>
                    {touched.tronAddress && errors.tronAddress}
                  </FormHelperText>
                </Box>
                <Box mt={2} className="endAdormentclx">
                  <Typography variant="body1">Amount</Typography>
                  <Box className="amountBoxClx">
                    <Box width="100%">
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        name="amount"
                        value={values.amount}
                        error={Boolean(touched.amount && errors.amount)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter withdraw amount"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="start">
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "rgba(255, 255, 255, 0.87)",
                                  }}
                                >
                                  TRX
                                </Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.amount && errors.amount}
                      </FormHelperText>
                    </Box>
                    <IconButton
                      className="iconButton"
                      onClick={() =>
                        setFieldValue("amount", userData?.balance?.toFixed(2))
                      }
                    >
                      <Typography
                        variant="body1"
                        style={{ color: "rgba(255, 255, 255, 0.87)" }}
                      >
                        Max
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>

                <Box mt={2} align="start">
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    placeholder="Please enter password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Box>
                <Box mt={2} className="balanceAvaiclx displaySpacebetween">
                  <Typography variant="body1">
                    Balance available for withdrawal
                  </Typography>
                  <span>
                    {`${
                      userData?.balance
                        ? Number(userData?.balance)?.toFixed(2)
                        : 0
                    } TRX`}{" "}
                  </span>
                </Box>

                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                  >
                    SUBMIT {isLoading && <LoadingComp />}
                  </Button>
                </Box>
                <Box className="nodeBox" mt={2}>
                  <Typography variant="subtitle1">
                    <img src="images/explanationMark.svg" />
                    &nbsp; Important:&nbsp;
                    <span>
                      Your withdrawal will be sent from the hot wallet, do not
                      withdraw to any site that uses the sending address, or
                      returns to sender, because any returns will probably be
                      credited to a different player.
                    </span>
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <WithdrawalHistoryModal
        confirmModal={withdrawHis}
        handleClose={() => setWithdrawHis(false)}
      />
    </>
  );
}
