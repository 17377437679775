import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { getAPIHandler } from "../ApiConfig/service";
import axios from "axios";
import { TiDocumentText } from "react-icons/ti";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
    "& .selectBox": {
      width: "140px",
    },
    "& .selectBox": {
      width: "140px",
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        padding: "1.5px 4px",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
}));

const tableHead = [
  {
    heading: "User",
  },
  {
    heading: "Multiplier",
  },
  {
    heading: "Bet Amount",
  },
  {
    heading: "Win Amount",
  },
];

export default function CrashGameModal({
  handleClose,
  confirmModal,
  storeViewData,
}) {
  console.log(">>>>>>>storeViewData", storeViewData);
  const classes = useStyles();
  const [gameHistoryData, setGameHistoryData] = useState([]);
  console.log("gameHistoryData", gameHistoryData);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [noOfPages, setNumPages] = useState(1);
  const [numTotalPages, setNumTotalPages] = useState(1);

  const getGameListHistoryApi = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: "crashGameValue",
        paramsData: {
          page: page,
          limit: 10,
          // time: moment(storeViewData?.createdAt).format("YYYY-MM-DD"),
          // time: storeViewData?.createdAt,
          hash:storeViewData.hash
        },
        source: source,
      });
      if (response.data.statusCode === 200) {
        // const filterTime = response?.data?.responseResult?.docs.filter(
        //   (item) =>
        //     moment(item?.createdAt).format("yyyy-mm-dd") ===
        //     moment(storeViewData?.createdAt).format("yyyy-mm-dd")
        // );
        // console.log(">>>>>>filterTime", filterTime);
        setGameHistoryData(response.data.responseResult.docs);
        setNumPages(response.data.responseResult.pages);
        setNumTotalPages(response.data.responseResult.total);
      } else {
        setGameHistoryData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setGameHistoryData([]);
    }
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    getGameListHistoryApi(source);
    return () => {
      source.cancel();
    };
  }, [page]);
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="sm"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <TiDocumentText />
          &nbsp;&nbsp;
          <Typography variant="h5">Crash Game</Typography>
        </Box>

        <DialogContent>
          <Box className="displayCenter">
            <Box className="displayColumn" mt={1} mb={1}>
              <Typography variant="h6">
                CrashGame: {storeViewData?.crashValue}
              </Typography>
              <Typography variant="body2" mt={1}>
                {moment(storeViewData?.createdAt).format("lll")
                  ? moment(storeViewData?.createdAt).format("lll")
                  : "--"}
              </Typography>
            </Box>
          </Box>

          <Typography variant="h5">Leaderboard</Typography>
          <Box className="mainBox" mt={1}>
            {gameHistoryData && (
              <TableComp
                tableHead={tableHead}
                scoreListData={
                  gameHistoryData &&
                  gameHistoryData?.map((item, i) => ({
                    User: item?.userId?.username ? item?.userId?.username : "",
                    Multiplier: item?.payoutMultiplier
                      ? `${item?.payoutMultiplier}x`
                      : "",
                    "Bet Amount": item?.betAmount ? item?.betAmount : "0",
                    "Win Amount": item?.winAmount
                      ? Number(item?.winAmount)?.toFixed(5)
                      : "0",
                  }))
                }
                noOfPages={noOfPages}
                noOfTotalPages={numTotalPages}
                page={page}
                setPage={setPage}
                limit={10}
                isLoading={isLoading}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
