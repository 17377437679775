import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  FormHelperText,
  DialogActions,
  Checkbox,
  Link,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { postAPIHandler, apiRouterCall } from "../ApiConfig/service";
import { toast } from "react-hot-toast";
import PassCodeModal from "./PassCodeModal";
import { AuthContext } from "../context/Auth";
import ApiConfig from "../ApiConfig/ApiConfig";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialogContent-root": {
      padding: "0px 13px",
      overflowY: "inherit",
    },
    "& .displayCenter": {
      justifyContent: "start",
      "& .MuiCheckbox-root": {
        padding: "4px 4px 4px 0px",
      },
    },
    "& .MuiDialogActions-root": {
      display: "block",
      // margin: "0px 38px",
      // marginBottom: "50px",
    },
    "& .MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: "0px",
    },
  },
  mainBox: {
    padding: "15px",
    borderRadius: "5px",
    background: "#1D1F23",
    // margin: "0px 38px",
    height: "calc(100dvh - 370px)",
    overflow: "auto",
    "& p": {
      color: "rgb(255 255 255 / 77%)",
      margin: "5px 0",
    },
  },
  backButton: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& svg": {
      color: "#fff",
    },
  },
  MainContent: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ConfirmSignUpModal({
  handleClose,
  confirmModal,
  setLoginModalOpen,
  setModalOpen,
  values,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [passCodeModalOpen, setPassCodeModalOpen] = useState(false);
  const [checkTerms, setCheckTerms] = useState({
    checkAge: false,
    checktermCon: false,
  });
  const [termsConditionData, setTermsConditionData] = useState({});

  const handleCreateAccount = async () => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "register",
        dataToSend: {
          email: values.email,
          username: values.username,
          // profession: values.profession,
          password: values.confirmPassword,
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success("OTP sent to your email, Please verify.");
        setIsLoading(false);
        setPassCodeModalOpen(true);
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  const handleGetStaticContent = async () => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        url: ApiConfig.viewStaticContenwWithType,
        paramsData: {
          type: "TermsConditions",
        },
      });
      if (response?.data?.statusCode === 200) {
        setTermsConditionData(response?.data?.responseResult);
        // toast.success(response?.data?.responseMessage);
      } else {
        // toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  useEffect(() => {
    handleGetStaticContent();
  }, []);
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setIsScrolledToEnd(bottom);
  };
  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="xs"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>

      <DialogContent onScroll={handleScroll}>
        <Box align="center" pb={0}>
          <Typography style={{ marginTop: "16px" }} variant="h5">
            CREATE AN ACCOUNT
          </Typography>
          <Box className={classes.backButton} mt={1}>
            <IconButton onClick={() => handleClose()}>
              <MdOutlineKeyboardBackspace />
            </IconButton>
            <Box width="100%">
              <Typography
                variant="body1"
                style={{ textAlign: "center", marginRight: "48px" }}
              >
                Read and accept the terms and conditions
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.mainBox}>
          <Typography variant="body2">
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: termsConditionData?.description,
              }}
            />
          </Typography>
        </Box>
        <Box className="displayCenter">
          <Checkbox
            checked={checkTerms?.checkAge}
            onChange={() =>
              setCheckTerms({ ...checkTerms, checkAge: !checkTerms?.checkAge })
            }
            disabled={isLoading}
          />
          <Typography variant="body1">I accept that I am 18+</Typography>
        </Box>
        <Box className="displayCenter">
          <Checkbox
            checked={checkTerms?.checktermCon}
            onChange={() =>
              setCheckTerms({
                ...checkTerms,
                checktermCon: !checkTerms?.checktermCon,
              })
            }
            disabled={isLoading}
          />
          <Typography variant="body1">
            I have read and agree to the terms and conditions.
          </Typography>
        </Box>
        {!isScrolledToEnd &&
          (!checkTerms?.checkAge || !checkTerms?.checktermCon) && (
            <FormHelperText error>
              Please read the terms and conditions in full and scroll to the end
              to accept.
            </FormHelperText>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          style={{ marginTop: "8px" }}
          disabled={
            !checkTerms?.checkAge || !checkTerms?.checktermCon || isLoading
          }
          onClick={() => handleCreateAccount()}
        >
          PLAY NOW {isLoading && <LoadingComp />}
        </Button>
        <Box mt={2} className={classes.MainContent}>
          <Typography variant="subtitle1">
            Already have an account?{" "}
            <span
              onClick={() => {
                if (!isLoading) {
                  setModalOpen(false);
                  handleClose();
                  setLoginModalOpen(true);
                }
              }}
            >
              Log In
            </span>
          </Typography>
        </Box>
      </DialogActions>
      <PassCodeModal
        handleClose={() => {
          setPassCodeModalOpen(false);
          setModalOpen(false);
          handleClose();
        }}
        confirmModal={passCodeModalOpen}
        setModalOpen={setModalOpen}
        data={values}
        auth={auth}
      />
    </Dialog>
  );
}
