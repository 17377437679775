import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { AiFillTrophy } from "react-icons/ai";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getAPIHandler } from "../ApiConfig/service";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthMd": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
    "& .selectBox": {
      width: "140px",
    },
    "& .selectBox": {
      width: "140px",
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        padding: "1.5px 4px",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
}));

const tableHead = [
  {
    heading: "#",
  },
  {
    heading: "PLAYER",
  },
  {
    heading: "WAGERED",
  },

  {
    heading: "PROFIT(ATH)",
  },
  {
    heading: "PROFIT(ATL)",
  },
  {
    heading: "BETS",
  },
];

const selectArray = ["All Time", "Last 24 hours", "Last 7 days", `Last month`];
export default function LeaderBoardModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [noOfTotalPages, setNoOfTotalPages] = useState("");
  const preFilledValue = "All Time";
  const [selectValue, setSelectValue] = useState(preFilledValue);
  const [leaderboardData, setLeaderBoardData] = useState([]);

  const getLeaderBoardData = async (source) => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "leaderBoard",
        source: source,
      });
      if (response.data.statusCode === 200) {
        setLeaderBoardData(response.data.responseResult);
        setIsLoading(false);
      } else {
        setLeaderBoardData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLeaderBoardData([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (confirmModal) {
      getLeaderBoardData(source);
    }
    return () => {
      source.cancel();
    };
  }, [confirmModal]);
  //Function to filter data based on the selected time range
  function filterDataByTimeRange(selectedTimeRange) {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    const oneWeek = 7 * oneDay; // One week in milliseconds
    const oneMonth = now.getMonth(); // One month in JavaScript is the previous month

    switch (selectedTimeRange) {
      case "All Time":
        return leaderboardData;

      case "Last 24 hours":
        const last24Hours = new Date(now - oneDay);
        return leaderboardData.filter(
          (item) => new Date(item.updatedAt) >= last24Hours
        );

      case "Last 7 days":
        const last7Days = new Date(now - oneWeek);
        return leaderboardData.filter(
          (item) => new Date(item.createdAt) >= last7Days
        );

      case "Last month":
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        return leaderboardData.filter(
          (item) => new Date(item.createdAt) >= lastMonth
        );
      case null:
        return [];
    }
  }

  const filteredData = useMemo(() => {
    return filterDataByTimeRange(selectValue);
  }, [selectValue, leaderboardData]);
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="md"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <AiFillTrophy />
          &nbsp;&nbsp;
          <Typography variant="h5">LEADERBOARD</Typography>
        </Box>

        <DialogContent>
          <Box mt={1.2} className="displaySpacebetween">
            <Typography variant="h6">ALL-TIME LEADERBOARD</Typography>
            <Box className="selectBox">
              <Select
                fullWidth
                style={{ height: "44px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                variant="outlined"
                value={selectValue}
                onChange={(e) => setSelectValue(e.target.value)}
              >
                {selectArray &&
                  selectArray.map((item, i) => (
                    <MenuItem style={{ color: "#fff" }} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
          <Box className="mainBox" mt={1}>
            <TableComp
              tableHead={tableHead}
              scoreListData={
                filteredData &&
                filteredData?.map((item, i) => ({
                  "#": i + 1,
                  PLAYER: item?.username ? item?.username : "0",
                  WAGERED: item?.wagered ? item?.wagered : "0",
                  "PROFIT(ATH)": item?.ath ? item?.ath : "0",
                  "PROFIT(ATL)": item?.atl ? item?.atl : "0",
                  BETS: item?.totalBets ? item?.totalBets : "0",
                }))
              }
              noOfPages={noOfPages}
              noOfTotalPages={noOfTotalPages}
              page={page}
              setPage={setPage}
              limit={10}
              isLoading={isLoading}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
