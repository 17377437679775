import React from "react";
import { Box } from "@material-ui/core";

const Logo = (props) => {
  return (
    <Box className="logoImg" onClick={() => window.open("/", "_self")}>
      <img src="/images/logo.png" alt="Logo" {...props} />
    </Box>
  );
};

export default Logo;
