import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  imgBox: {
    maxWidth: "300px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "200px",
      width: "100%",
    },
  },
  mainBox: {
    "& h5": {
      marginTop: "-20px",
    },
  },
}));
export default function NoDataFound({ text }) {
  const classes = useStyles();
  return (
    <Box className={`${classes.mainBox} displayCenter`} mt={1} mb={1}>
      <Box align="center">
        <img
          src="/images/noDataFondImage.png"
          alt="nodata"
          className={classes.imgBox}
        />
        <Typography variant="h5">{text ? text : "No data found"}</Typography>
      </Box>
    </Box>
  );
}
