import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#26272B !important",
      },
      elevation2: {
        background: "#26272B !important",
        borderRadius: "25px",
        border: "1px solid rgba(0, 0, 0, 0.30)",
        padding: "30px",
        boxShadow: "none",
        backdropFilter: "blur(20px)",
      },
      elevation3: {
        background: "#26272B !important",
        borderRadius: "10px",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        padding: "30px",
        boxShadow: "none",
        backdropFilter: "blur(20px)",
      },
    },

    MuiAutocomplete: {
      option: {
        color: "#fff",
        fontSize: "14px !important",
        fontWeight: "400",
        letterSpacing: "0px",
        textAlign: "left",
        background: "#1d1f23",
        borderRadius: "5px",
        // height: "40px",
      },
      input: {
        width: "0",
        // minWidth: "30px",
        color: "#fff",
        fontSize: "14px !important",
        fontWeight: "400",
      },
    },

    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: "#1d1d1d",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
      iconButton: {
        padding: "6px",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
      },
      dayDisabled: {
        color: "#52565c",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "5px",
        background: "#2F3034",
      },
      input: {
        padding: "15.5px 14px",
        color: "#fff",

        borderRadius: "5px",
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
      adornedStart: {
        padding: "3px 3px 3px 3px !important",
        "& .MuiIconButton-edgeStart": {
          "& svg": {
            color: "rgba(255, 255, 255, 0.60)",
            fontSize: "18px",
          },
        },
      },
      inputAdornedStart: {
        background: " #1D1F23",
        paddingLeft: "10px",
      },
      inputAdornedEnd: {
        paddingRight: "38px",
      },
      notchedOutline: {
        borderColor: "rgb(0 0 0 / 11%)",
      },
      adornedEnd: {
        "& .MuiInputAdornment-positionEnd": {
          position: "absolute",
          right: "7px",
        },
      },
    },

    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },

    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#999999" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
        background: " #26272B !important",
        boxShadow: "none !important",
        color: "#fff",
      },
      paperWidthSm: {
        maxWidth: "600px",
        background: "#2D2D2D",
        borderRadius: "8px",
      },
      paperWidthMd: {
        maxWidth: "800px",
        background: "#2D2D2D",
        borderRadius: "8px",
      },
    },
    MuiTimelineConnector: {
      root: {
        width: "2px",
        height: "108px",
        flexGrow: "1",
        backgroundColor: "transparent",
        backgroundImage: "url(./images/line.png)",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "rgba(255, 255, 255, 0.25)",
        height: "1.1876em",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
        },
        "&::after": {},
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
      outlinedSecondary: {
        border: "1px solid #EB7D2D",
        color: "#EB7D2D",
        "&:hover": {
          color: "#fff",
          background:
            "0 1px 0 0 #EB7D2D, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #EB7D2D, -1px 0 0 0 #EB7D2D, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #CA772A, -1px -1px 0 0 rgb(244 168 26)",
          backgroundColor:
            "0 1px 0 0 #EB7D2D, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #EB7D2D, -1px 0 0 0 #EB7D2D, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #CA772A, -1px -1px 0 0 rgb(244 168 26)",
          border: "1px solid #EB7D2D",
        },
      },
      containedSecondary: {
        backgroundColor: "#484949",
        borderRadius: "5px",
        color: "#fff",
        fontSize: "14px",
        height: "44px",
        whiteSpace: "pre",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 10%)",
        "&:hover": {
          color: "#ffffff",
          background: "#484949",
          backgroundColor: "#484949",
        },
      },
      containedSizeSmall: {
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "12px",
      },
      containedPrimary: {
        backgroundColor: "#EB7D2D",
        backgroundBlendMode: "overlay",
        color: "#fff",
        borderRadius: "5px",
        whiteSpace: "pre",
        fontSize: "14px",
        lineHeight: "21px",
        padding: "10px 35px",
        fontWeight: "500",

        "&:hover": {
          color: "#fff",
          boxShadow:
            "0 1px 0 0 #EB7D2D, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #EB7D2D, -1px 0 0 0 #EB7D2D, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #CA772A, -1px -1px 0 0 rgb(244 168 26)",
          backgroundColor: "transparent",
          background: "transparent",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#ffffff",
        fontWeight: 500,
        padding: "5px 19px",
        "&.Mui-disabled": {
          color: "#ffffff8a",
        },
      },
      containedSizeLarge: {
        height: "47px",
        padding: "8px 36px",
        fontSize: "16px",
      },
      outlinedPrimary: {
        color: "#ffffff",
        border: "solid 1px transparent",
        height: "43px",
        padding: "11px 35px",
        fontSize: "12px",
        boxShadow: "2px 1000px 1px #1b0f20 inset",
        fontWeight: "500",
        whiteSpace: "pre",
        borderRadius: "50px",
        background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
        backgroundOrigin: "border-box",
        "@media(max-width:767px)": {
          padding: "7px 40px",
        },
        "@media(max-width:599.9px)": {
          padding: "7px 25px",
        },
        "&:hover": {
          color: "#ffffff",
          border: "solid 1px transparent",
          boxShadow: "2px 1000px 1px #1f0f23 inset",
          background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
          backgroundOrigin: "border-box",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
      outlinedSizeLarge: {
        height: "48px",
        padding: "8px 36px",
        fontSize: "16px",
        borderRadius: "50px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
    MuiMenu: {
      // paper: { top: "47px" },
      paper: {
        backgroundColor: "#2D2D2D",
      },
    },
    MuiDropzoneArea: {
      root: {
        background:
          "linear-gradient(0deg, rgb(53 50 50 / 15%), rgb(58 53 53 / 15%)),linear-gradient(0deg, rgb(66 63 63 / 4%), rgb(58 54 54 / 4%))",

        border: "1px dashed rgba(255, 255, 255, 0.3)",
        borderColor: "rgba(255, 255, 255, 0.3)",
        minHeight: "228px",
        zIndex: "9",
        backgroundColor: "none",
        borderRadius: "10px",
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "12px",
      },
    },
    MuiSelect: {
      selectMenu: {
        paddingBottom: "20px",
        paddingTop: "20px",
      },
    },
    MuiTableHead: {
      root: {
        background: "#282a2e",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiTableBody: {
      root: {
        background: "#282a2e",
      },
    },
    MuiTableRow: {
      root: {
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiTableCell: {
      head: {
        padding: "8px 14px",
        fontWeight: "300",
        color: "#fff",
        whiteSpace: "pre",
        fontSize: "14px",
      },
      body: {
        color: "rgba(255, 255, 255, 0.60)",
        whiteSpace: "pre",
      },
      root: {
        borderBottom: "none",
        padding: "14px",
        fontSize: "14px",
      },
    },

    MuiTypography: {
      subtitle1: {
        // color: "rgba(255, 255, 255, 0.6)",
        color: "#fff",
        fontSize: "12px",
        fontWeight: 300,
        colorSecondary: {
          color: "rgba(255, 255, 255, 0.6)",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Sora', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
