import {
  Box,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  Avatar,
  DialogActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose, CgPlayListSearch } from "react-icons/cg";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
    },
    "& .MuiDialogActions-root": {
      padding: "0px !important",
      justifyContent: "center !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 28px",
      },
    },
    "& .userBox": {
      background: "#1D1F23",
      padding: "10px",
      margin: "14px 0px",
      "& .borderClx": {
        border: "1px dotted #EB7D2D",
        padding: "2px",
        borderRadius: "50%",
      },
      "& h6": {
        fontSize: "14px",
      },
      "& p": {
        fontSize: "13px",
      },
    },

    "& .MuiDialogContent-root": {
      padding: "4px 0px",
      overflow: "hidden",
    },
    "& .nodeBox": {
      "& h6": {
        textAlign: "center",
        fontSize: "11px",
        fontWeight: 300,
        "& span": {
          color: "#EB7D2D",
          fontWeight: 600,
          cursor: "pointer",
        },
      },
    },
  },

  tableBoxData: {
    padding: "12px",
    background: "#1D1F23",
    "& .contentData": {
      padding: "2px 0px",
      "& p": {
        fontSize: "13px",
      },
      "& h6": {
        fontSize: "13px",
        fontWeight: "300px",
        color: "#EB7D2D",
        marginTop: "4px",
      },
    },
  },
}));

export default function BankOverViewModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="sm"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: 0, top: 0 }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <Box className="displayStart">
        <CgPlayListSearch />
        &nbsp;&nbsp;
        <Typography variant="h5">OVERVIEW</Typography>
      </Box>

      <DialogContent>
        <Box className="userBox tabDisplayBox">
          <Box className="borderClx">
            <Avatar src="images/imageSec.png" alt="ABHINAV GUPTA" />
          </Box>
          &nbsp;&nbsp;
          <Box>
            <Typography variant="h6">ABHINAV GUPTA</Typography>
            <Typography variant="body1">
              OINED: Aug 14, 2023 40 minutes ago
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            {" "}
            <Box className={classes.tableBoxData}>
              <Box className="contentData displaySpacebetween">
                <Typography variant="body1">Your share</Typography>
                <Typography variant="h6">0.00000000 TRX</Typography>
              </Box>
              <Box className="contentData displaySpacebetween">
                <Typography variant="body1">Your investing profit</Typography>
                <Typography variant="h6">0.00000000 TRX</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box className={classes.tableBoxData}>
              <Box className="contentData displaySpacebetween">
                <Typography variant="body1">Bustatrx's bankroll</Typography>
                <Typography variant="h6">1,944.88862781 TRX</Typography>
              </Box>
              <Box className="contentData displaySpacebetween">
                <Typography variant="body1">Investor’s total profit</Typography>
                <Typography variant="h6">7,027.05586835 TRX</Typography>
              </Box>
              <Box className="contentData displaySpacebetween">
                <Typography variant="body1">Commission rate</Typography>
                <Typography variant="h6">
                  73.1% (zero for the next TRX 7)
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box className="nodeBox" mt={1}>
          <Typography variant="h6">
            <img src="images/explanationMark.svg" />
            &nbsp;<span> Important:</span>&nbsp; If you want to learn more about
            the bankroll, click <span>Here.</span>
          </Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
