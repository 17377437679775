import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import OverViewModal from "../../../component/OverViewModal";
import StatsModal from "../../../component/StatsModal";
import SecurityModal from "../../../component/SecurityModal";
import ConfirmModal from "../../../component/ConfirmModal";
import WithdrawalModal from "../../../component/WithdrawalModal";
import TipsModal from "../../../component/TipsModal";
import BankOverViewModal from "../../../component/BankOverViewModal";
import ChangeBankRollModal from "../../../component/ChangeBankRollModal";
import BankRollHistory from "../../../component/BankRollHistory";
import BackTestingModal from "../../../component/BackTestingModal";
import StatisticsModal from "../../../component/StatisticsModal";
import LeaderBoardModal from "../../../component/LeaderBoardModal";
import HelpSupportModal from "../../../component/HelpSupportModal";
import SettingModal from "../../../component/SettingModal";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/Auth";
import { ToastBar, toast } from "react-hot-toast";
import { backTesttingbaseurl } from "../../../ApiConfig/ApiConfig";
import MakeDepositModal from "../../../component/MakeDepositModal";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiList-padding": {
    paddingTop: "19px !important",
    paddingBottom: "8px",
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    // padding: "0px 10px",
  },
  button: {
    color: "#000000",
    padding: "10px 15px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontSize: "16px",
  },
  buttonLeaf: {
    color: "rgba(255, 255, 255, 0.60)",
    padding: "5px 5px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontSize: "16px",
    margin: "0px 5px",
    "&:hover": {
      color: " #fff",
      borderRadius: "10px",
      background: "rgba(72, 73, 73, 0.20)",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.20)",
      "& svg": {
        color: " #fff",
      },
    },
    "&.active": {
      background: "#ec7d2d",
      color: " #fff",
      borderRadius: "10px",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.20)",
      "& svg": {
        color: " #fff",
      },
    },
    "&:active": {
      background: "#ec7d2d",
      color: " #fff",
      borderRadius: "10px",
      boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.20)",
      "& svg": {
        color: " #fff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: "400",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.60)",
  },
  title: {
    marginRight: "auto",
  },
  title1: {
    marginRight: "auto",
    fontSize: "12px",
  },
  active: {
    color: "#fff",
    fontSize: "18px",
    borderRadius: "10px",
    background: "#ec7d2d !important",
    boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.20)",
    // margin: "0px 5px",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
      color: "#fff",
      "& svg": {
        color: "#fff",
      },
    },
    "& $icon": {
      color: "#fff",
    },
  },
  iconCircle: {
    width: "36px",
    height: "36px",
    backgroundColor: "rgb(29 26 26 / 3%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 8px",
    borderRadius: "50%",
    "& svg": {
      // width: "100%",
      // maxWidth: "21px",
      width: "695px",
      maxWidth: "239px",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  img,
  modal,
  info: Info,
  open: openProp,
  title,
  icon: Icon,
  ...rest
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState(openProp);
  const [openModal, setOpenModal] = useState({
    overview: false,
    stats: false,
    security: false,
    deposit: false,
    withdrawal: false,
    tips: false,
    bankoverview: false,
    changebankroll: false,
    bankhistory: false,
    backtesting: false,
    statistics: false,
    leaderboard: false,
    help: false,
    setting: false,
    logout: false,
  });

  let paddingLeft = 8;
  let fontSize = 16;
  if (depth > 0) {
    paddingLeft = 32 + 27 * depth;
    fontSize = 16;
  }
  const style = { paddingLeft, fontSize };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    auth.setOpenDrower(true);
  };

  const handleRouteCall = (href) => {
    const modalKey = href?.split("/")[1];
    if (
      window.sessionStorage.getItem("token") ||
      modalKey === "logout" ||
      modalKey === "help" ||
      modalKey === "leaderboard" ||
      modalKey === "backtesting"
    ) {
      if (modalKey) {
        modalKey === "backtesting" && window.open(`${backTesttingbaseurl}`);
        modalKey !== "backtesting" &&
          setOpenModal({ ...openModal, [modalKey]: true });
        auth?.userData?.userType === "ADMIN" &&
          modalKey !== "logout" &&
          window.open(`${href}`, "_self");
      } else {
        window.open("/dashboard", "_self");
      }
    } else {
      toast.error("Please login first.");
    }
  };

  const HandleLogout = () => {
    auth.userLogIn(false, null);
    window.localStorage.removeItem("token");
    window.open("/", "_self");
  };

  const isActive = history.location.pathname === href;

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          activeClassName={classes.active}
          onClick={handleToggle}
          style={{ color: "#ccc" }}
        >
          <Box className={classes.iconCircle}>
            {Icon && <Icon className={classes.icon} size="20" />}
          </Box>
          {auth.openDrower && (
            <span className={children ? classes.title : classes.title1}>
              {title}
            </span>
          )}

          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        className={clsx(
          classes.itemLeaf,
          className,
          isActive && classes.active
        )}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          style={style}
          onClick={() => {
            handleRouteCall(href);
          }}
        >
          {Icon && (
            <Box className={classes.iconCircle}>
              <Icon className={classes.icon} size="20" />
            </Box>
          )}
          {auth.openDrower && (
            <span className={Icon ? classes.title : classes.title1}>
              {title}
            </span>
          )}

          {Info && <Info />}
        </Button>
        {openModal.overview && (
          <OverViewModal
            handleClose={() => setOpenModal({ ...openModal, overview: false })}
            confirmModal={openModal.overview}
          />
        )}
        {openModal.stats && (
          <StatsModal
            handleClose={() => setOpenModal({ ...openModal, stats: false })}
            confirmModal={openModal.stats}
          />
        )}
        {openModal.security && (
          <SecurityModal
            handleClose={() => setOpenModal({ ...openModal, security: false })}
            confirmModal={openModal.security}
          />
        )}
        {openModal.deposit && (
          <MakeDepositModal
            handleClose={() => setOpenModal({ ...openModal, deposit: false })}
            confirmModal={openModal.deposit}
          />
        )}
        {openModal.withdrawal && (
          <WithdrawalModal
            handleClose={() =>
              setOpenModal({ ...openModal, withdrawal: false })
            }
            confirmModal={openModal.withdrawal}
          />
        )}
        {openModal.tips && (
          <TipsModal
            handleClose={() => setOpenModal({ ...openModal, tips: false })}
            confirmModal={openModal.tips}
          />
        )}
        {openModal.bankoverview && (
          <BankOverViewModal
            handleClose={() =>
              setOpenModal({ ...openModal, bankoverview: false })
            }
            confirmModal={openModal.bankoverview}
          />
        )}
        {openModal.changebankroll && (
          <ChangeBankRollModal
            handleClose={() =>
              setOpenModal({ ...openModal, changebankroll: false })
            }
            confirmModal={openModal.changebankroll}
          />
        )}
        {openModal.bankhistory && (
          <BankRollHistory
            handleClose={() =>
              setOpenModal({ ...openModal, bankhistory: false })
            }
            confirmModal={openModal.bankhistory}
          />
        )}
        {openModal.backtesting && (
          <BackTestingModal
            handleClose={() =>
              setOpenModal({ ...openModal, backtesting: false })
            }
            confirmModal={openModal.backtesting}
          />
        )}
        {openModal.statistics && (
          <StatisticsModal
            handleClose={() =>
              setOpenModal({ ...openModal, statistics: false })
            }
            confirmModal={openModal.statistics}
          />
        )}
        {openModal.leaderboard && (
          <LeaderBoardModal
            handleClose={() =>
              setOpenModal({ ...openModal, leaderboard: false })
            }
            confirmModal={openModal.leaderboard}
          />
        )}
        {openModal.help && (
          <HelpSupportModal
            handleClose={() => setOpenModal({ ...openModal, help: false })}
            confirmModal={openModal.help}
          />
        )}
        {openModal.setting && (
          <SettingModal
            handleClose={() => setOpenModal({ ...openModal, setting: false })}
            confirmModal={openModal.setting}
          />
        )}
        {openModal.logout && (
          <ConfirmModal
            handleClose={() => setOpenModal({ ...openModal, logout: false })}
            confirmModal={openModal.logout}
            title="logout"
            text="Are you sure, you want to logout?"
            isLoading={false}
            handleSubmit={() => HandleLogout()}
          />
        )}
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  modal: PropTypes.bool,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
