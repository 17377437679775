import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import TableComp from "./TableComp";
import { CgClose } from "react-icons/cg";
import { GiBackwardTime } from "react-icons/gi";
import { getAPIHandler } from "../ApiConfig/service";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
  },
}));

const tableHead = [
  {
    heading: "DATE & TIME",
  },
  {
    heading: "COUNTERPARTY",
  },
  {
    heading: "TIPS TYPE",
  },
  {
    heading: "STATUS",
  },
  {
    heading: "AMOUNT",
  },
];
export default function TipsHistoryModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNumPages] = useState(1);
  const [numTotalPages, setNumTotalPages] = useState(1);
  const [tipsHistoryData, setTipsHistoryData] = useState([]);

  const getTipsHistory = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "tipsHistory",
        paramsData: {
          page: page,
          limit: 10,
        },
      });

      if (response?.data?.statusCode == 200) {
        setTipsHistoryData(response?.data?.responseresult?.docs);
        setNumPages(response.data?.responseresult?.pages);
        setNumTotalPages(response.data?.responseresult?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (confirmModal) {
      getTipsHistory();
    }
  }, [confirmModal, page]);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <GiBackwardTime color="#fff" />
          &nbsp;&nbsp;
          <Typography variant="h5">TIPS HISTORY</Typography>
        </Box>

        <DialogContent>
          <Box className="mainBox" mt={1.4}>
            <TableComp
              tableHead={tableHead}
              scoreListData={
                tipsHistoryData &&
                tipsHistoryData.map((item) => ({
                  "DATE & TIME": moment(item?.createdAt).format("lll"),
                  COUNTERPARTY: item?.receiverId?.username,
                  "TIPS TYPE" : item?.type=== "receiver" ? 'INCOMING' : 'OUTGOING',
                  STATUS: item.transactionStatus,
                  AMOUNT: item.totalOutgoingTips
                    ? item.totalOutgoingTips
                    : item.totalIncomingTips,
                }))
              }
              noOfPages={noOfPages}
              noOfTotalPages={numTotalPages}
              page={page}
              setPage={setPage}
              limit={10}
              isLoading={isLoading}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
