import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { AuthContext } from "../../context/Auth";
import { AiFillHome } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: " 5px 0px",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "60px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#000",
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
}));

export default function Header() {
  const { toolbar, drawerContainer, drawericon, logoDrawer, mainHeader } =
    useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const exitButton = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          style={{ whiteSpace: "pre" }}
          onClick={() =>
            window.location.pathname === "/leaderboard"
              ? history.push("/derby")
              : history.goBack()
          }
        >
          Exit
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          className="TopbarButton"
          color="secondary"
          style={{ whiteSpace: "pre", padding: "10px 22px", fontSize: "18px" }}
          onClick={() => history.push("/")}
        >
          <AiFillHome />
        </Button>
      </>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          {exitButton()}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
          <Box className="centerLogoBox">{femmecubatorLogo}</Box>
          {getDrawerChoices()}
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            {getDrawerChoices()}
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {exitButton()}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#fec712", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return (
      <>
        <Button
          variant="contained"
          className="TopbarButton"
          color="secondary"
          style={{ whiteSpace: "pre", padding: "10px 22px" }}
        >
          <img src="images/buttonicon_1.png" alt="iamge" />
          &nbsp;
        </Button>
        <Button
          variant="contained"
          className="TopbarButton"
          color="secondary"
          style={{
            whiteSpace: "pre",
            position: "relative",
            padding: "10px 25px 10px 50px",
          }}
        >
          <img
            src={auth.account ? "images/buttonicon_2.png" : "images/lock.png"}
            alt="iamge"
            className="centerImg"
            style={
              auth.account
                ? {}
                : {
                    maxWidth: "27px",
                    marginRight: "0px",
                    marginLeft: "12px",
                  }
            }
          />{" "}
          Connect Wallet
        </Button>
        <Button
          variant="contained"
          className="TopbarButton"
          color="secondary"
          style={{ whiteSpace: "pre", padding: "10px 22px" }}
        >
          <img src="images/buttonicon_3.png" alt="iamge" /> How to Play
        </Button>
      </>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
        }}
        className="hedaerTopbar"
      >
        <Container maxWidth="fixed">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
