import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { CgClose } from "react-icons/cg";
// import LoadingComp from "./LoadingComp";
import { RiLockPasswordFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/Auth";
import { postAPIHandler } from "../../ApiConfig/service";
import LoadingComp from "../LoadingComp";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
  },
}));

export default function VerifyModal({ open, setOpen, handleClose }) {
  const classes = useStyles();
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    passcode: "",
  };

  const validationSchema = yup.object().shape({
    passcode: yup
      .string()
      .required("Please enter passcode.")
      .matches(/^[0-9]{6}$/, "Passcode must be exactly 6 digits."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "verifyWithdrawOtp",
        dataToSend: {
          email: userData.email,
          otp: values.passcode,
        },
      });
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        setOpen(false);
        handleClose();
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => !isLoading && setOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        disabled={isLoading}
        onClick={() => setOpen(false)}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitDataFun(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Box align="center" mb={2} p={3}>
                <Typography variant="h5">Withdraw Verification</Typography>
                <Box mt={2} align="start">
                  <Typography variant="body1">Passcode</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="passcode"
                    placeholder="Please enter code"
                    value={values.passcode}
                    error={Boolean(touched.passcode && errors.passcode)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center" style={{ color: "#fff" }}>
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onKeyDown={(event) => handleNegativeValue(event)}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/" ||
                        event?.key === "."
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <FormHelperText error>
                    {touched.passcode && errors.passcode}
                  </FormHelperText>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Verify {isLoading && <LoadingComp />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
