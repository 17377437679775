import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { toast } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { apiRouterCall } from "../ApiConfig/service";
import ApiConfig from "../ApiConfig/ApiConfig";
import { AuthContext } from "../context/Auth";
import QRCode from "qrcode.react";
import DepositHistoryModal from "./DepositHistoryModal";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },

    "& .nodeBox": {
      border: "2px dotted #54555B",
      padding: "12px",
      borderRadius: "5px",
      "& p": {
        fontSize: "13px",
        display: "flex",
        "& span": {
          color: "rgba(255, 255, 255, 0.60)",
          fontSize: "12px",
        },
      },
    },
  },
}));
export default function MakeDepositModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [viewDepositHistory, setViewDepositHistory] = useState(false);
  const { userData } = useContext(AuthContext);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <img src="images/DepositIcon.svg" />
          &nbsp;&nbsp;
          <Typography variant="h5">DEPOSIT</Typography>
        </Box>

        <Typography variant="h6" style={{ marginTop: "16px" }}>
          TRON DEPOSIT ADDRESS
        </Typography>

        <Box align="center" mt={2}>
          <QRCode
            value={userData?.address}
            fgColor="#000"
            includeMargin
            width={256}
            height={256}
            size={200}
            renderAs="canvas"
            imageSettings={{
              src: "images/crashGameIcon.svg",
              excavate: true,
              scale: 2,
              width: 55,
              height: 55,
            }}
          />
        </Box>

        <DialogContent>
          <Box mt={2} className="endAdormentclx">
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              name="account"
              value={userData && userData?.address}
              placeholder="sdsggsgsgcsssxzxusw645rhdgsdsdsndns"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={userData && userData?.address}>
                      <IconButton
                        edge="start"
                        onClick={() => toast.success("Copied")}
                      >
                        <MdContentCopy />
                        &nbsp;
                        <Typography
                          variant="body2"
                          style={{ color: "rgba(255, 255, 255, 0.60)" }}
                        >
                          Copy
                        </Typography>
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="nodeBox" mt={2}>
            <Typography variant="body1">
              <img src="images/explanationMark.svg" />
              &nbsp; Important:&nbsp;
              <span>Send only </span>&nbsp; TRX (TRC-20) &nbsp;
              <span> to this address.</span>
            </Typography>
          </Box>

          <Box align="center">
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={() => setViewDepositHistory(true)}
            >
              HISTORY {isLoading && <LoadingComp />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {viewDepositHistory && (
        <DepositHistoryModal
          handleClose={() => setViewDepositHistory(false)}
          confirmModal={viewDepositHistory}
        />
      )}
    </>
  );
}
