import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { Formik, Form } from "formik";
import * as yup from "yup";
import ResetPasswordModal from "./ResetPasswordModal";
import { postAPIHandler } from "../ApiConfig/service";
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({}));

export default function ForgotPasswordModal({
  setForgotModalOpen,
  confirmModal,
  setLoginModalOpen,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "forgotPassword",
        dataToSend: {
          email: values.email,
          redirectUrl: window.location.href,
        },
      });
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        setLoginModalOpen(false);
        setForgotModalOpen(false);
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && setForgotModalOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        disabled={isLoading}
        onClick={() => setForgotModalOpen(false)}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitDataFun(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Box align="center" mb={2} p={3} className="dialodcustomBox">
                <Typography variant="h5">FORGOT PASSWORD</Typography>
                <Box mt={2} align="start">
                  <Typography variant="body1">Email</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    placeholder="Please enter email address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="images/emailImg.svg" alt="email" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.email && errors.email}
                  </FormHelperText>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  RECOVER PASSWORD {isLoading && <LoadingComp />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
