import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { FaBell } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { getAPIHandler, putAPIHandler } from "../ApiConfig/service";
import moment from "moment";
import NoDataFound from "./NoDataFound";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import ButtonCircularProgress from "./ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthSm": {
      padding: "25px !important",
    },
    "& .MuiDialog-paperWidthXs": {
      padding: "15px !important",
    },
    "& .displayStart": {
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .mainBox": {
      background: "#1D1F23",
      padding: "12px",
    },
    "& .selectBox": {
      width: "140px",
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        padding: "1.5px 4px",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
}));

export default function NotificationModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getNotificationData = async (source) => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getNotification",
        source: source,
      });
      if (response.data.statusCode === 200) {
        setIsLoading(false);
        setNotificationData(response.data.responseResult);
      } else {
        setIsLoading(false);
        setNotificationData([]);
      }
    } catch (error) {
      console.error("Error fetching notification data:", error);
      setIsLoading(false);
      setNotificationData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDeleteModal = (value, type) => {
    setSelectedNotificationId(value);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (type) => {
    const source = axios.CancelToken.source();
    let bodyRequired;
    if (type === "SINGLE" && selectedNotificationId) {
      bodyRequired = {
        _id: selectedNotificationId,
      };
    } else if (type === "ALL") {
      bodyRequired = {
        type: "ALL",
      };
    }

    if (bodyRequired) {
      try {
        setIsLoading1(true);
        const response = await putAPIHandler({
          endPoint: "deleteNotification",
          source: source,
          paramsData: bodyRequired,
        });
        if (response.data.statusCode === 200) {
          getNotificationData(source);
          setIsLoading1(false);
        } else {
          setIsLoading1(false);
        }
      } catch (error) {
        console.error("Error deleting notification:", error);
        setIsLoading1(false);
      } finally {
        setIsLoading1(false);
        setSelectedNotificationId(null);
        setDeleteModalOpen(false);
        setClearAllModalOpen(false);
      }
    }
  };

  const handleClearAllClick = () => {
    setClearAllModalOpen(true);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getNotificationData(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="sm"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box
          mt={2}
          className="displaySpacebetween"
          style={{ borderBottom: "1px solid #484949", paddingBottom: "12px" }}
        >
          <Box className="displayStart">
            <FaBell color="#fff" />
            &nbsp;&nbsp;
            <Typography variant="h5">Notifications</Typography>
          </Box>
          <Box onClick={handleClearAllClick}>
            <Typography variant="body2" style={{ cursor: "pointer" }}>
              Clear All
            </Typography>
          </Box>
        </Box>

        <DialogContent>
          <Box
            className="mainNotificationComponentBox"
            style={{ maxHeight: "calc(100vh - 168px)", overflow: "auto" }}
          >
            <Box className="mainNotificationBox" style={{ height: "100%" }}>
              {isLoading && (
                <Box mt={2} mb={2} className="displayCenter">
                  <Typography variant="h6"> Loading...</Typography>
                </Box>
              )}
              {!isLoading &&
                notificationData?.reverse()?.map((data, index) => (
                  <Box className="filtersButton" key={index}>
                    <Box>
                      <Box
                        className="displaySpacebetween"
                        style={{
                          borderBottom: "1px solid #484949",
                          padding: "8px 0px 10px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            width: "100%",
                            maxWidth: "433px",
                          }}
                        >
                          <IconButton
                            style={{
                              cursor: "pointer",
                              padding: "0px",
                              marginRight: "20px",
                            }}
                          >
                            <FaBell color="#EB7D2D" />
                          </IconButton>
                          <Typography variant="body2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data?.message || "N/A",
                              }}
                            ></div>
                          </Typography>
                        </Box>
                        <Box className="displayEnd dateText">
                          <Box className="displayCenter">
                            <Typography
                              variant="body1"
                              style={{ marginBottom: "4px" }}
                            >
                              {moment(data?.createdAt).format("lll") || "--"}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleOpenDeleteModal(data?._id, "SINGLE");
                              }}
                            >
                              <MdDelete style={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              {!isLoading &&
                notificationData &&
                notificationData?.length === 0 && (
                  <NoDataFound text={"No notification data found!"} />
                )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={clearAllModalOpen}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <Box align="center">
          <Box my={1}>
            <Typography variant="h5" color="secondary">
              Clear all notifications
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="body2" color="secondary">
              Are you sure you want to clear all notifications?
            </Typography>
          </Box>
          <Box className="displayCenter" mt={4} mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConfirmDelete("ALL")}
              disabled={isLoading1}
            >
              Yes {isLoading1 && <ButtonCircularProgress />}
            </Button>
            <Box ml={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setClearAllModalOpen(false)}
                style={{ background: "#636363" }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={deleteModalOpen}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <Box align="center">
          <Box my={1}>
            <Typography variant="h5" color="secondary">
              Delete Notification
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="body2" color="secondary">
              Are you sure you want to delete this notification?
            </Typography>
          </Box>
          <Box className="displayCenter" mt={4} mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConfirmDelete("SINGLE")}
              disabled={isLoading1}
            >
              Yes {isLoading1 && <ButtonCircularProgress />}
            </Button>
            <Box ml={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDeleteModalOpen(false)}
                style={{ background: "#636363" }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
