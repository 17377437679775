import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useContext } from "react";
import { AuthContext } from "../context/Auth";
import { toast } from "react-hot-toast";
import PassCodeModal from "./PassCodeModal";
import { postAPIHandler } from "../ApiConfig/service";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { handleNegativeValue } from "../utils";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& span": {
      color: "#EB7D2D",
      cursor: "pointer",
    },
  },
}));

export default function LoginModal({
  setLoginModalOpen,
  confirmModal,
  setModalOpen,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [twoFaActive, setTwoFaActive] = useState(false);
  const [passCodeModalOpen, setPassCodeModalOpen] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    twoFaCode: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .test("emailPhoneUsername", "Invalid input.", (value) => {
        const phoneRegex = /^[0-9]{10}$/; // Adjust to your specific phone number format
        const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/; // Adjust min and max length as needed
        return (
          !!value &&
          (yup.string().email().isValidSync(value) ||
            (phoneRegex.test(value) &&
              !/(\d)\1{6,}/.test(value.replace(/[^0-9]/g, "")) &&
              value.length >= 7 &&
              value.length <= 13) ||
            usernameRegex.test(value))
        );
      })
      .required("Please enter email/username."),
    password: yup
      .string()
      .trim()
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
      //   "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
      // )
      .required("Please enter password."),
    // .min(6, "Please enter atleast 6 characters.")
    // .max(18, "You can enter only 18 characters."),
    twoFaCode: twoFaActive
      ? yup
          .string()
          .required("Please enter 2FA code.")
          .matches(/^[0-9]{6}$/, "Code must be exactly 6 digits.")
      : "",
  });

  const handleSubmitDataFun = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "login",
        dataToSend: {
          username: values.email,
          password: values.password,
          twoFaCode: values.twoFaCode ? values.twoFaCode : undefined,
        },
      });
      console.log(">>>>>>>>response", response);
      if (response.data.statusCode === 200) {
        if (response.data.responseResult.otpVerified) {
          if (response.data.responseResult.twofactorauthentication) {
            if (
              response.data.responseResult.twofactorauthentication &&
              !twoFaActive
            ) {
              setTwoFaActive(true);
              setIsLoading(false);
            } else {
              setTwoFaActive(false);
              handleLoginData(response);
            }
          } else {
            handleLoginData(response);
            if (response?.data?.responseResult?.userType === "ADMIN") {
              window.open("/admin-dashboard", "_self");
            }
          }
        } else {
          toast.success(response?.data?.responseMessage);
          setIsLoading(false);
          setPassCodeModalOpen(true);
        }
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  const handleLoginData = (response) => {
    setIsLoading(false);
    window.sessionStorage.setItem("token", response.data.responseResult.token);
    toast.success(response?.data?.responseMessage);
    auth.userLogIn(true, response.data.responseResult.token);
    auth.getUserProfile(response.data.responseResult.token);
    setLoginModalOpen(false);
  };

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => {
          if (!isLoading) {
            setTwoFaActive(false);
            setLoginModalOpen(false);
          }
        }}
        fullWidth
        maxWidth="xs"
      >
        <IconButton
          style={{ position: "absolute", right: "0px" }}
          disabled={isLoading}
          onClick={() => {
            if (!isLoading) {
              setTwoFaActive(false);
              setLoginModalOpen(false);
            }
          }}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitDataFun(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box align="center" mb={2} p={3} className="dialodcustomBox">
                  <Typography variant="h5">LOGIN</Typography>
                  <Box mt={2} align="start">
                    <Typography variant="body1">Email/Username</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="email"
                      placeholder="Please enter email/username"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="images/emailImg.svg" alt="email" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Box>

                  <Box mt={2} align="start">
                    <Typography variant="body1">Password</Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Please enter password"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="center">
                              <img src="./images/passlock.png" alt="passlock" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <HiEye className={classes.iconsclass1} />
                              ) : (
                                <HiEyeOff className={classes.iconsclass1} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box className="displaySpacebetween" mt={0.3}>
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>
                      {!twoFaActive && (
                        <Typography
                          variant="body1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!isLoading) {
                              setTwoFaActive(false);
                              setForgotModalOpen(true);
                            }
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {twoFaActive && (
                    <Box align="start" mt={1}>
                      <Typography variant="body1">passlock</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="twoFaCode"
                        placeholder="Please enter code"
                        value={values.twoFaCode}
                        error={Boolean(touched.twoFaCode && errors.twoFaCode)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isLoading}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="center">
                                <img
                                  src="./images/passlock.png"
                                  alt="passlock"
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/" ||
                            event?.key === "."
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <FormHelperText error>
                        {touched.twoFaCode && errors.twoFaCode}
                      </FormHelperText>
                    </Box>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "20px" }}
                    disabled={isLoading}
                  >
                    LOGIN {isLoading && <LoadingComp />}
                  </Button>

                  <Box mt={2} className={classes.mainBox}>
                    <Typography variant="subtitle1">
                      Don't have an account?{" "}
                      <span
                        onClick={() => {
                          if (!isLoading) {
                            setTwoFaActive(false);
                            setLoginModalOpen(false);
                            setModalOpen(true);
                          }
                        }}
                      >
                        Register an account
                      </span>
                    </Typography>
                  </Box>
                </Box>

                <ForgotPasswordModal
                  setForgotModalOpen={setForgotModalOpen}
                  confirmModal={forgotModalOpen}
                  setLoginModalOpen={setLoginModalOpen}
                />
                {passCodeModalOpen && (
                  <PassCodeModal
                    handleClose={() => {
                      setPassCodeModalOpen(false);
                      setModalOpen(false);
                    }}
                    confirmModal={passCodeModalOpen}
                    setLoginModalOpen={setLoginModalOpen}
                    setModalOpen={setModalOpen}
                    data={values.email}
                    auth={auth}
                    otpType="Login"
                  />
                )}
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
