import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  DialogActions,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { VscGraph } from "react-icons/vsc";
import { getAPIHandler } from "../ApiConfig/service";
import axios from "axios";
import LoadingComp from "./LoadingComp";
import NoDataFound from "./NoDataFound";
import GraphComponent from "./GraphComponent";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthMd": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 16px",
      },
    },
    "& .contentData": {
      background: "#1D1F23",
      padding: "6px 12px",
      marginTop: "4px",
      "& p": {
        fontSize: "13px",
      },
      "& h6": {
        fontSize: "13px",
        fontWeight: "300px",
        color: "#EB7D2D",
        marginTop: "4px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
    "& .nodeBox": {
      "& h6": {
        textAlign: "center",
        fontSize: "11px",
        fontWeight: 300,
        "& span": {
          color: "#EB7D2D",
          fontWeight: 600,
          cursor: "pointer",
        },
      },
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  tableBoxData: {
    padding: "12px",
    background: "#1D1F23",
    "& .contentData": {
      padding: "3px 0px",
      "& p": {
        fontSize: "14px",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "300px",
        color: "#EB7D2D",
        marginTop: "4px",
      },
    },
    "& .borderClxText": {
      borderBottom: "1px solid #484949",
    },
  },
  graphBox: {
    background: "#1D1F23",
    textAlign: "center",
    "& h6": {
      paddingBottom: "16px",
      marginTop: "-12px",
    },
    "& .displayCenter": {
      justifyContent: "none",
      "& h6": {
        transform: " rotate(-90deg)",
        width: "37px",
        padding: "0px",
        marginTop: "2px",
      },
    },
  },
}));

export default function StatisticsModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState();
  const [graphData, setGraphData] = useState();

  const getPlatformStats = async (source) => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "stats",
        source: source,
      });
      if (response.data.statusCode === 200) {
        // setStatsData(
        //   response.data.responseresult &&
        //     Object.entries(response.data.responseresult)
        // );
        setStatsData(response?.data?.responseResult);
        setGraphData(response?.data?.responseResult?.graphRes);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (confirmModal) {
      getPlatformStats(source);
    }
    return () => {
      source.cancel();
    };
  }, [confirmModal]);

  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="md"
      className={classes.mainRoot}
    >
      <IconButton
        style={{ position: "absolute", right: 0, top: 0 }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
      </IconButton>
      <Box className="displayStart">
        <VscGraph />
        &nbsp;&nbsp;
        <Typography variant="h5">STATISTICS</Typography>
      </Box>

      <Typography variant="h6">PERFORMANCE</Typography>
      <DialogContent>
        <Box className={classes.tableBoxData}>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Games Played</Typography>
            <Typography variant="h6">
              {statsData?.totalBets ? statsData?.totalBets : "--"}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween borderClxText">
            <Typography variant="body1">Total Wagered</Typography>
            <Typography variant="h6">
              {`${statsData?.wagerAmount ? statsData?.wagerAmount : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Net Profit</Typography>
            <Typography variant="h6">
              {`${statsData?.totalProfit ? statsData?.totalProfit : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Profit All Time High</Typography>
            <Typography variant="h6">
              {`${statsData?.highProfit ? statsData?.highProfit : "--"} TRX`}
            </Typography>
          </Box>
          <Box className="contentData displaySpacebetween">
            <Typography variant="body1">Profit All Time Low</Typography>
            <Typography variant="h6">
              {`${statsData?.lowProfit ? statsData?.lowProfit : "--"} TRX`}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.graphBox} mt={2}>
          <Box className="displayCenter">
            <Typography variant="h6">PROFIT</Typography>
            <GraphComponent data={graphData} isLoading={isLoading} />
          </Box>
          <Typography variant="h6">TIME</Typography>
        </Box>
      </DialogContent>
      {statsData === undefined && <NoDataFound text="No Bets found." />}
      {isLoading && <LoadingComp />}
    </Dialog>
  );
}
