import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { putAPIHandler } from "../ApiConfig/service";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import { AuthContext } from "../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },
  },
}));
export default function UpdatePasswordModal({
  handleClose,
  confirmModal,
  closeSecurityModal,
}) {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPass: false,
  });

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .trim()
      .required("Please enter old password.")
      .min(6, "Please enter at least 6 characters.")
      .max(18, "You can enter up to 18 characters."),
    password: yup
      .string()
      .trim()
      .notOneOf(
        [yup.ref("oldPassword"), null],
        "Password cannot be the same as old password."
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]{8,}$/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number, and one special character."
      )
      .required("Please enter password.")
      .min(6, "Please enter at least 6 characters.")
      .max(18, "You can enter up to 18 characters."),
    confirmPassword: yup
      .string()
      .required("Please enter confirm password.")
      .oneOf([yup.ref("password"), null], "Passwords must match."),
  });

  const handleUpdatePassword = async (values) => {
    try {
      setIsLoading(true);
      const response = await putAPIHandler({
        endPoint: "updatePassword",
        dataToSend: {
          oldPassword: values.oldPassword,
          newPassword: values.password,
          confirmNewPassword: values.confirmPassword,
        },
      });
      if (response?.data?.statusCode === 200) {
        setIsLoading(false);
        auth.userLogIn(false, null);
        toast.success(response?.data?.responseMessage);
        window.localStorage.removeItem("token");
        closeSecurityModal();
        handleClose();
        window.open("/dashboard", "_self");
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <RiLockPasswordFill />
          &nbsp;&nbsp;
          <Typography variant="h5">UPDATE PASSWORD</Typography>
        </Box>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleUpdatePassword(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={2} align="start">
                  <Typography variant="body1">Old Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.oldPassword ? "text" : "password"}
                    name="oldPassword"
                    placeholder="Please enter old password"
                    value={values.oldPassword}
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                oldPassword: !showPassword.oldPassword,
                              })
                            }
                          >
                            {showPassword.oldPassword ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.oldPassword && errors.oldPassword}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    placeholder="Please enter password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                password: !showPassword.password,
                              })
                            }
                          >
                            {showPassword.password ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">Confirm Password</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={showPassword.confirmPass ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Please enter confirm password"
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="center">
                            <img src="./images/passlock.png" alt="passlock" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPass: !showPassword.confirmPass,
                              })
                            }
                          >
                            {showPassword.confirmPass ? (
                              <HiEye className={classes.iconsclass1} />
                            ) : (
                              <HiEyeOff className={classes.iconsclass1} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.confirmPassword && errors.confirmPassword}
                  </FormHelperText>
                </Box>
                <Box align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: "20px" }}
                    disabled={isLoading}
                  >
                    CONTINUE {isLoading && <LoadingComp />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
