import React, {useState,useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Box } from "@material-ui/core";
import { apiRouterCall } from "../../ApiConfig/service";

import { toast } from "react-hot-toast";
import ApiConfig from "../../ApiConfig/ApiConfig";

function Terms({ open, handleClose }) {
  const [termsConditionData, setTermsConditionData] = useState({});

   const handleGetStaticContent = async () => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        url : ApiConfig.viewStaticContenwWithType,
        paramsData:{
          type : 'AboutUs'
        },
      });
      if (response?.data?.statusCode === 200) {
        setTermsConditionData(response?.data?.responseResult)
        // toast.success(response?.data?.responseMessage);
      } else {
        // toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  useEffect(() => {
    handleGetStaticContent()
  }, []);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <IconButton
        onClick={handleClose}
        style={{ position: "absolute", right: "0" }}
      >
        <CloseIcon style={{ color: "#fff" }} />
      </IconButton>
      <DialogTitle>
        <Box align="left">
          <Typography variant="h2" style={{textAlign:"left"}}> Terms of services</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {" "}
          <div
                        dangerouslySetInnerHTML={{
                          __html: termsConditionData?.description,
                        }}
                      />
        </Typography>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default Terms;
