import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { FaUserAlt } from "react-icons/fa";
import { postAPIHandler } from "../ApiConfig/service";
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    "& .MuiDialog-paperWidthXs": {
      padding: "25px !important",
    },
    "& .displayStart": {
      borderBottom: "1px solid #484949",
      paddingBottom: "12px",
      "& svg": {
        fontSize: " 17px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "4px 0px",
    },

    "& .textField": {
      marginTop: "16px",
      "& span": {
        color: "#EB7D2D",
        fontSize: "12px",
        fontWeight: 600,
      },
    },
  },
}));
export default function ContactUsModal({ handleClose, confirmModal }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    username: "",
    email: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),
    username: yup
      .string()
      .required("Username is required.")
      .matches(/^[a-zA-Z0-9_]{3,20}$/, "Please enter a valid username.")
      .min(3, "Please enter atleast 3 characters.")
      .max(32, "You can enter only 32 characters."),
    message: yup
      .string()
      .required("Message is required.")
      .min(3, "Please enter atleast 3 characters.")
      .max(600, "You can enter only 600 characters."),
  });

  const handleSubmitContact = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "contactUs",
        dataToSend: {
          email: values.email,
          username: values.username,
          message: values.message,
        },
      });
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        handleClose();
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => !isLoading && handleClose()}
        fullWidth
        maxWidth="xs"
        className={classes.mainRoot}
      >
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          disabled={isLoading}
          onClick={() => handleClose()}
        >
          <CgClose style={{ color: "#908f8e", fontSize: "18px" }} />
        </IconButton>
        <Box className="displayStart">
          <img src="images/contactUsIcon.svg" />
          &nbsp;&nbsp;
          <Typography variant="h5">CONTACT US</Typography>
        </Box>

        <Typography variant="subtitle1" className="textField">
          We're here to help Check our{" "}
          <span onClick={() => handleClose()} style={{ cursor: "pointer" }}>
            common support questions
          </span>{" "}
          Didn't find an answer? Submit a message through this form and we will
          email you back.
        </Typography>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitContact(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Box mt={1} align="start">
                  <Typography variant="body1">Username</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="username"
                    placeholder="Please enter username"
                    value={values.username}
                    error={Boolean(touched.username && errors.username)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="center">
                          <IconButton edge="center" style={{color:"#fff",fontSize:"16px"}}>
                            <FaUserAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.username && errors.username}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start">
                  <Typography variant="body1">Email</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    placeholder="Please enter email address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="center">
                          <IconButton edge="center" style={{paddingLeft: "13px",
    paddingRight: "19px"}}>
                            <img src="images/emailImg.svg" alt="email" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {touched.email && errors.email}
                  </FormHelperText>
                </Box>
                <Box mt={2} align="start" className="multilineField">
                  <Typography variant="body1">Message</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="message"
                    multiline
                    placeholder="Type Something....."
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isLoading}
                    rows="6"
                  />

                  <Box className="displaySpacebetween">
                    <FormHelperText error>
                      {touched.message && errors.message}
                    </FormHelperText>
                    <Typography variant="subtitle1">
                      {values.message.length}/600
                    </Typography>
                  </Box>
                </Box>

                <Box align="center" mt={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    SUBMIT {isLoading && <LoadingComp />}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleClose()}
                    disabled={isLoading}
                  >
                    BACK TO SUPPORT INDEX
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
