import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import HomeLayout from "./layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../src/views/pages/LandingPage")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/HomePageCompoent")),
  },
  {
    exact: true,
    path: "/dashboardold",
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/HomeDashboard")),
  },
  {
    exact: true,
    path: "/account",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Testingfile")),
  },
  // {
  //   exact: true,
  //   path: "/withdraw-management",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("./views/pages/admin/withdrawManagement")),
  // },
  {
    exact: true,
    guard: true,
    path: "/withdraw-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/CashierManagement/MainWithdraw")
    ),
  },
  // Admin panal Routes...

  {
    exact: true,
    guard: true,
    path: "/admin-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/admin/Dashboard/Dashboard")),
  },

  {
    exact: true,
    guard: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/userManagement/UserManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/userManagement/ViewManagementTab")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-adminManagement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/SubAdminManagement/SubAdmin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/SubAdminManagement/AddSubAdmin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/deposit-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/CashierManagement/DepositeManagement")
    ),
  },
  {
    exact: true,
    path: "/withdrawal-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/CashierManagement/ WithdrawalManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/tips-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/CashierManagement/TipsManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/fee-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/FeeManagement/FeeManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/StaticManagement/StaticManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/FaqManagement/FaqManagement")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/admin/FaqManagement/AddFaq")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/admin/Setting/EditProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/admin/Setting/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/term-service",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/admin/StaticManagement/TermServices")
    ),
  },
  {
    exact: true,

    path: "/hash-address",
    layout: DashboardLayout,
    component: lazy(() => import("./component/SearchDataByHash")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/errors/NotFound")),
  },
  {
    component: () => window.open("/404", "_self"),
  },
];
